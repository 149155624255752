<template>
    <div class="row">
        <div class="col-6">
            <card type="tasks" class="stacked-form">
                <div class="col-6 table-full-width table-responsive">
                    <base-input>
                        <label>Notificação:</label>
                        <el-select v-model="notificationEmail.NotificationType" placeholder="Selecione"
                            @change="changeTypeNotification" :disabled="disabledNotificationType">
                            <el-option v-for="item in notificationEmailOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </base-input>
                </div>
                <div class="col-12 table-full-width table-responsive">
                    <base-input>
                        <label>Assunto:</label>
                        <el-input placeholder="Informar o Assunto" v-model="notificationEmail.Subject">
                        </el-input>
                    </base-input>
                </div>
                <div class="col-12 table-full-width table-responsive">
                    <label>Corpo:</label>
                    <vue-editor v-model="notificationEmail.Body" :editorToolbar="customToolbar"
                        :editorOptions="customEditorOptions"></vue-editor>
                </div>
                <div class="col-6" v-if="showFrequency">
                    <base-input>
                        <label>Frequência:</label>
                        <el-tooltip content="Intervalo de tempo que a verificação deve ser repetida." effect="light"
                            :open-delay="100" placement="top">
                            <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                        </el-tooltip>
                        <el-select v-model="notificationEmail.Frequency" class="m-2"
                            placeholder="Selecione a Frequência" size="large" filterable>
                            <el-option v-for="item in optionsFrequency" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </base-input>
                </div>
                <div class="col-12" v-else>
                    <base-input>
                        <label>Regra:</label>
                        <el-tooltip content="Defina a condição para realizar a notificação." effect="light"
                            :open-delay="100" placement="top">
                            <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                        </el-tooltip>
                        <el-input v-if="notificationEmail.NotificationType === 3" type="number"
                            style="padding-top: 16px;" placeholder="Informar o Estoque Mínimo"
                            v-model="notificationEmail.Rule" :precision="2" :step="0.1" :max="1000" :min="0"></el-input>
                        <el-input v-if="notificationEmail.NotificationType === 4" type="number"
                            placeholder="Informar a Porcentagem de variação do preço" style="padding-top: 16px;"
                            v-model="notificationEmail.Rule" :precision="2" :step="0.1" :max="100" :min="0"></el-input>
                    </base-input>
                </div>
                <div class="col-6" v-if="notificationEmail.NotificationType === 1">
                    <base-input>
                        <label>Regra:</label>
                        <el-tooltip content="Tempo de pedidos sem integração." effect="light" :open-delay="100"
                            placement="top">
                            <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                        </el-tooltip>
                        <el-select v-model="notificationEmail.Rule" class="m-2" placeholder="Selecione a Regra"
                            size="large" filterable>
                            <el-option v-for="item in optionsFrequencyRule" :key="item.value" :label="item.label"
                                :value="item.value" />
                        </el-select>
                    </base-input>
                </div>
                <div class="col-4 table-full-width table-responsive">
                    <base-input>
                        <label>Status:</label><br>
                        <el-switch v-model="notificationEmail.Active" active-text="Ativo" inactive-text="Inativo">
                        </el-switch>
                    </base-input>
                </div>
            </card>
        </div>
        <div class="col-6">
            <card type="tasks" class="stacked-form">
                <h4 class="card-title">Legenda</h4>
                <div class="col-12">
                    <el-table :data="tagsData">
                        <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                            :prop="column.prop" :label="column.label" align="center"></el-table-column>
                    </el-table>
                </div>
                <br />
                <h5><strong>* Utilize a TAG para personalizar o e-mail</strong></h5>
                <br /><br /><br /><br /><br /><br />
            </card>
        </div>
        <div class="col-12">
            <card type="tasks" class="stacked-form">
                <h4 class="card-title">Destinatários
                    <base-button type="primary" small class="btn-next float-right" @click="showModalRecipients">
                        Adicionar
                    </base-button>
                </h4>
                <div class="col-12">
                    <el-table :data="notificationEmail.NotificationEmailRecipients">
                        <el-table-column v-for="column in tableColumnsRecipients" :key="column.label"
                            :min-width="column.minWidth" :prop="column.prop" :label="column.label" align="center">
                        </el-table-column>
                        <el-table-column :min-width="135" align="right" label="Ações">
                            <div slot-scope="props">
                                <base-button class="like !important btn-link" type="danger" size="sm" icon
                                    @click="Delete(props.row)">
                                    <i class="material-icons">delete_outline</i>
                                </base-button>
                            </div>
                        </el-table-column>
                    </el-table>
                </div>
                <NotificationEmailRecipients :show="showModal" @evtAddedCondition="saveCondition"
                    @evtClose="closeModalCondition" />
            </card>
        </div>
        <div class="col-12" v-if="!showFrequency">
            <card type="tasks" class="stacked-form">
                <h4 class="card-title">Exceção SKU
                    <base-button type="primary" small class="btn-next float-right" @click="showModalSku">
                        Adicionar
                    </base-button>
                </h4>
                <div class="col-12">
                    <el-table :data="notificationEmail.NotificationEmailSkus">
                        <el-table-column label="SKU" align="center" prop="Sku">
                        </el-table-column>
                        <el-table-column :min-width="135" align="right" label="Ações">
                            <div slot-scope="props">
                                <base-button class="like !important btn-link" type="danger" size="sm" icon
                                    @click="Delete(props.row)">
                                    <i class="material-icons">delete_outline</i>
                                </base-button>
                            </div>
                        </el-table-column>
                    </el-table>
                </div>
                <NotificationEmailSkus :show="showModalNotificationSku" @evtAddedCondition="saveConditionSku"
                    @evtClose="closeModalCondition" />
            </card>
        </div>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import swal from "sweetalert2";
import { mapActions, mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import NotificationEmailRecipients from './NotificationEmailRecipients.vue';
import NotificationEmailSkus from "./NotificationEmailSkus.vue";

export default {
    components: {
        NotificationEmailRecipients,
        NotificationEmailSkus,
        VueEditor
    },
    computed: {
        ...mapState('notificationEmail', ['notificationEmailCurrent']),
        ...mapState(['ecommerce'])
    },
    props: {
        typeForm: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            customToolbar: [
                ["bold", "italic", "underline"]
            ],
            customEditorOptions: {
                formats: [
                    "bold", "header", "italic", "link", "list", "blockquote", "image", "indent"
                ],
            },
            optionsFrequency: [
                { value: '*/5 * * * *', label: '5 minutos' },
                { value: '*/10 * * * *', label: '10 minutos' },
                { value: '*/15 * * * *', label: '15 minutos' },
                { value: '*/30 * * * *', label: '30 minutos' },
                { value: '0 */1 * * *', label: '1 hora' },
                { value: '0 */2 * * *', label: '2 horas' },
                { value: '0 */3 * * *', label: '3 horas' },
                { value: '0 */4 * * *', label: '4 horas' },
                { value: '0 */5 * * *', label: '5 horas' },
                { value: '0 */6 * * *', label: '6 horas' },
                { value: '0 */7 * * *', label: '7 horas' },
                { value: '0 */8 * * *', label: '8 horas' }
            ],
            optionsFrequencyRule: [
                { value: 5, label: '5 minutos' },
                { value: 10, label: '10 minutos' },
                { value: 15, label: '15 minutos' },
                { value: 30, label: '30 minutos' },
                { value: 60, label: '1 hora' },
                { value: 120, label: '2 horas' },
                { value: 180, label: '3 horas' },
                { value: 240, label: '4 horas' },
                { value: 300, label: '5 horas' },
                { value: 360, label: '6 horas' },
                { value: 420, label: '7 horas' },
                { value: 480, label: '8 horas' }
            ],
            notificationEmail: {
                EcommerceName: "",
                Active: true,
                NotificationType: null,
                Subject: null,
                Body: null,
                ErpId: this.$store.state.ecommerce.Erp.Id,
                NotificationEmailRecipients: [],
                NotificationEmailSkus: []
            },
            statusOptions: [
                { value: false, label: 'Inativo' },
                { value: true, label: 'Ativo' }
            ],
            notificationEmailOptions: [
                { value: 1, label: 'Avisar ao ultrapassar X minutos sem pedidos integrados' },
                { value: 2, label: 'Avisar a cada pedido com erro' },
                { value: 3, label: 'Estoque' },
                { value: 4, label: 'Preço' },
                { value: 5, label: 'Pedido sem integração' },
                { value: 6, label: 'Comunicação com ERP' },
                { value: 7, label: 'Comunicação com XS Engine' },
                { value: 8, label: 'Pedidos cancelados' },
                { value: 9, label: 'Pedidos aguardando cancelamento' }
            ],
            tableColumns: [
                {
                    prop: "Tag",
                    label: "Tag",
                    minWidth: 100,
                },
                {
                    prop: "Description",
                    label: "Descrição",
                    minWidth: 100,
                }
            ],
            tableColumnsRecipients: [
                {
                    prop: "Name",
                    label: "Nome"
                },
                {
                    prop: "Email",
                    label: "E-mail"
                }
            ],
            tagsData: [
                {
                    Tag: '@orderNumber',
                    Description: 'Número do Pedido'
                },
                {
                    Tag: '@productSku',
                    Description: 'SKU do Produto'
                },
                {
                    Tag: '@orderNumberSap',
                    Description: 'Número do Pedido no SAP'
                },
                // Melhoria a ser desenvolvida;
                /*
                {
                    Tag: '@productName',
                    Description: 'Nome do Produto'
                }, {
                    Tag: '@productStock',
                    Description: 'Quantidade em estoque do Produto'
                },
                {
                    Tag: '@productPrice',
                    Description: 'Preço do Produto'
                },
                */
            ],
            recipientsData: [],
            showModal: false,
            showModalNotificationSku: false,
            showFrequency: true,
            disabledNotificationType: false
        }
    },

    methods: {
        ...mapActions('notificationEmail'),
        ...mapActions('configurations', ['getConfigurations']),
        save() {
            this.$emit("save");
        },
        validate() {
            if (!this.notificationEmail.Subject) {
                this.$showError("Assunto do e-mail obrigatório.");
                return false;
            } else if (!this.notificationEmail.Body) {
                this.$showError("Corpo do e-mail obrigatório.");
                return false;
            } else if (!this.notificationEmail.NotificationType) {
                this.$showError("Tipo de notificação obrigatória.");
                return false;
            } else if (!this.notificationEmail.Frequency &&
                (this.notificationEmail.NotificationType !== 3 && this.notificationEmail.NotificationType !== 4)) {
                this.$showError("Frequência obrigatória.");
                return false;
            } else
                return true;
        },
        getValue() {
            return this.notificationEmail;
        },
        load() {
            this.notificationEmail = this.notificationEmailCurrent;
            if (this.notificationEmailCurrent) {
                this.disabledNotificationType = true;
            }
            if (this.notificationEmailCurrent.NotificationType === 3 || this.notificationEmailCurrent.NotificationType === 4) {
                this.showFrequency = false;
            }
            this.recipientsData = this.notificationEmailRecipients;
        },
        showModalRecipients() {
            this.showModal = true;
        },
        showModalSku() {
            this.showModalNotificationSku = true;
        },
        saveCondition(model) {
            this.notificationEmail.NotificationEmailRecipients.push(model);
            this.showModal = false;
        },
        saveConditionSku(model) {
            this.notificationEmail.NotificationEmailSkus.push(model);
            this.showModalNotificationSku = false;
        },
        closeModalCondition() {
            this.showModal = false;
            this.showModalNotificationSku = false;
        },
        Delete: function (object) {
            swal({
                title: "Gostaria de excluir o cadastro?",
                text: "Esse processo não poderá ser revertido.",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                cancelButtonText: "Não",
                confirmButtonText: "Sim",
            }).then(async (result) => {
                if (result.value) {
                    await this.remove(object)
                }
            });
        },
        async remove(object) {
            if (object.hasOwnProperty('Email')) {
                var pos = this.notificationEmail.NotificationEmailRecipients.indexOf(object);
                this.notificationEmail.NotificationEmailRecipients.splice(pos, 1);
            } else if ((object.hasOwnProperty('Sku'))) {
                var pos = this.notificationEmail.NotificationEmailSkus.indexOf(object);
                this.notificationEmail.NotificationEmailSkus.splice(pos, 1);
            }
        },
        changeTypeNotification(type) {
            switch (type) {
                case 1:
                    this.showFrequency = true;
                    break;
                case 2:
                    this.showFrequency = true;
                    break;
                case 3:
                    this.showFrequency = false;
                    break;
                case 4:
                    this.showFrequency = false;
                    break;
            }
        }
    },

    mounted() {
        EventBus.$on("loadNotificationEmail", () => this.load());
    },
}
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
    {
    opacity: 0;
}

textarea {
    background: transparent !important;
    border-color: #2b3553 !important;
}

.el-textarea__inner:focus {
    outline: 0;
    border-color: #00ADB7 !important;
}

.el-textarea__inner:hover {
    border-color: #00ADB7 !important;
}

.el-select .el-input.is-focus .el-input__inner {
    border-color: #00ADB7;
    color: #00ADB7;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7;
    background-color: #00ADB7;
}

.el-switch__label.is-active {
    color: #00ADB7;
}

.el-switch__label {
    -webkit-transition: .2s;
    transition: .2s;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
    color: #e9ecef;
}

.el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>
