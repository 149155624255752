<template>
  <div>
    <div class="row">
      <div class="col-4">
        <card type="tasks">
          <h4 id="v-step-22">Documentos ERP</h4>
          <div v-if="Order" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.Order" :sync="true" />
            <label style="margin-left: 10px">Pedido de venda</label>
          </div>
          <div v-if="BusinessPartner" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.BusinessPartner" :sync="true" />
            <label style="margin-left: 10px">Cliente</label>
          </div>
          <div v-if="IncomingPayment" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.IncomingPayment" :sync="true" />
            <label style="margin-left: 10px">Contas a Receber</label>
          </div>
          <div v-if="Invoice" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.Invoice" :sync="true" />
            <label style="margin-left: 10px">Nota Fiscal</label>
          </div>
          <div v-if="DownPayment" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.DownPayment" :sync="true" />
            <label style="margin-left: 10px">Adiantamento</label>
          </div>
          <div v-if="Picking" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.Picking" :sync="true" />
            <label style="margin-left: 10px">Picking</label>
          </div>
          <div v-if="InvoiceTransfer" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.InvoiceTransfer" :sync="true" />
            <label style="margin-left: 10px">Nota Fiscal de Saída (Transferência)</label>
          </div>
          <div v-if="PurchaseDeliveryNotes" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.PurchaseDeliveryNotes" :sync="true" />
            <label style="margin-left: 10px">Recebimento de Mercadoria (Transferência)</label>
          </div>
          <div v-if="PurchaseInvoice" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.PurchaseInvoice" :sync="true" />
            <label style="margin-left: 10px">Nota Fiscal de Entrada (Transferência)</label>
          </div>
          <div v-if="CreditNotes" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.CreditNotes" :sync="true" />
            <label style="margin-left: 10px">Dev. Nota Fiscal Saída (Fulfillment)</label>
          </div>
          <div v-if="StockTransfer" class="col-12 table-full-width table-responsive">
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              v-model="ErpDocuments.StockTransfer" :sync="true" />
            <label style="margin-left: 10px">Transferência de Estoque (Fulfillment)</label>
          </div>
        </card>
      </div>
    </div>
    <div class="col-lg-12 col-sm-12">
      <UpdateLog :dataLog="dataLog" />
    </div>
    <div class="row">
      <div class="col-12">
        <div class="
                        d-flex
                        justify-content-center justify-content-between
                        flex-wrap flex-row-reverse
                      ">
          <button class="custom-btn-save" @click="saveERPDocuments()" v-if="getUserPermission('19_c')">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import planMixin from "src/mixins/planMixin.js";
import { mapActions, mapState } from "vuex";
import UpdateLog from "../../../components/UpdateLog.vue";

export default {
  mixins: [planMixin],
  components: {
    UpdateLog
  },
  computed: {
    ...mapState(["realm", "ecommerce"])
  },
  data() {
    return {
      dataLog: [],
      ErpDocuments: {
        Id: 0,
        Order: false,
        BusinessPartner: false,
        IncomingPayment: false,
        Invoice: false,
        DownPayment: false,
        Picking: false,
        InvoiceTransfer: false,
        PurchaseDeliveryNotes: false,
        PurchaseInvoice: false,
        CreditNotes:false,
        StockTransfer:false
      },
      createNew: false,
      Order: false,
      BusinessPartner: false,
      IncomingPayment: false,
      Invoice: false,
      DownPayment: false,
      Picking: false,
      InvoiceTransfer: false,
      PurchaseDeliveryNotes: false,
      PurchaseInvoice: false,
      CreditNotes:false,
      StockTransfer:false,
      checkFunctionality: false
    };
  },
  async mounted() {
    this.init();
    //await this.getPlan();
    //await this.getFunctionalities(this.isPlanControlActive);

    this.checkFunctionality = true;
    this.DownPayment = true;
    this.Picking = true;
    this.Invoice = true;
    this.Order = true;
    this.BusinessPartner = true;
    this.IncomingPayment = true;
    this.InvoiceTransfer = true;
    this.PurchaseDeliveryNotes = true;
    this.PurchaseInvoice = true;
    this.CreditNotes = true;
    this.StockTransfer = true;
  },
  methods: {
    ...mapActions('configurations', ['createErpDocuments']),
    init: async function () {
      await this.$bahngleis.get(
        `ErpDocuments/GetByEcommerceName?ecommerceName=${this.ecommerce.Name}`
      ).then((response) => {
        if (response.data) {
          this.ErpDocuments = response.data;
        } else {
          this.createNew = true;
        }
      });

      await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        this.ecommerce.Name +
        "&entity=ErpDocuments"
      ).then(response => {
        this.dataLog = response.data;
      });

    },
    saveERPDocuments: async function () {
      this.ErpDocuments.EcommerceName = this.ecommerce.Name;
      if (this.createNew) {
        await this.createErpDocuments(this.ErpDocuments)
          .then(() => {
            swal({
              title: "Sucesso",
              text: `O cadastro foi salvo`,
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false
            });
          })
          .catch((error) => this.$showError(error.response.data));
      }
      else {
        let vm = this;
        let status = "";
        await this.$bahngleis
          .put("ErpDocuments/", this.ErpDocuments)
          .then(function () {
            if (vm.ErpDocuments.Id != 0) {
              status = "atualizado";
            } else {
              status = "salvo";
            }
            swal({
              title: "Sucesso",
              text: `O cadastro foi ${status}`,
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false
            });
          })
          .catch(function () {
            swal({
              title: "Atenção",
              text: "Ocorreu um erro ao realizar a requisição",
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false
            });
          });
      }

      this.init();
    },
    enableFields: async function () {
      if (
        this.functionalities.find(
          f => f.identifier === "habilitar-pedido-venda"
        )
      ) {
        this.Order = true;
      }
      if (
        this.functionalities.find(f => f.identifier === "habilitar-cliente")
      ) {
        this.BusinessPartner = true;
      }
      if (this.functionalities.find(f => f.identifier === "IncomingPayment")) {
        this.IncomingPayment = true;
      }
      if (
        this.functionalities.find(f => f.identifier === "geracao-nota-fiscal")
      ) {
        this.Invoice = true;
      }
      if (this.functionalities.find(f => f.identifier === "DownPayment")) {
        this.DownPayment = true;
      }
      if (this.functionalities.find(f => f.identifier === "Picking")) {
        this.Picking = true;
      }
      if (this.functionalities.find(f => f.identifier === "InvoiceTransfer")) {
        this.InvoiceTransfer = true;
      }
      if (
        this.functionalities.find(f => f.identifier === "PurchaseDeliveryNotes")
      ) {
        this.PurchaseDeliveryNotes = true;
      }
      if (this.functionalities.find(f => f.identifier === "PurchaseInvoice")) {
        this.PurchaseInvoice = true;
      }
      if (this.functionalities.find(f => f.identifier === "CreditNotes")) {
        this.CreditNotes = true;
      }
      if (this.functionalities.find(f => f.identifier === "StockTransfer")) {
        this.StockTransfer = true;
      }
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  }
};
</script>
