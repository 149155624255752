<template>
  <div class="row">
    <div class="col-12">
      <card type="tasks" class="stacked-form">
        <div class="col-3">
          <div class="col-12">
            <base-input label="Nome:">
              <el-input v-model="form.Name" />
            </base-input>
          </div>
          <div class="col-12">
            <base-input label="Regra para:">
              <el-select v-model="form.TypeRule" placeholder="Selecione regra para" :disabled="disabledField">
                <el-option v-for="item in typeOptions" :key="item.Key" :label="item.Label" :value="item.Key">
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-12">
            <base-input label="% de Frete:" v-if="form.TypeRule == 6">
              <el-input placeholder="Informar a % de frete" type="number"
                min="0" v-model="form.DefaultValue"></el-input>
            </base-input>
            <base-input label="% Crédito Presumido:" v-else-if="form.TypeRule == 7">
              <el-input placeholder="Informar a % de crédito presumido" type="number"
                min="0" v-model="form.DefaultValue"></el-input>
            </base-input>
            <base-input label="Valor a ser integrado:" v-else>
              <el-select v-if="form.TypeRule == 1" class="select-primary" v-model="form.DefaultValue" filterable>
                <el-option v-for="option in branchs" :value="option.Key" :label="option.Label" :key="option.Key"
                  filterable></el-option>
              </el-select>
              <el-select v-else-if="form.TypeRule == 3" class="select-primary" v-model="form.DefaultValue">
                <el-option v-for="option in warehouses" :value="option.Key" :label="option.Label" :key="option.Key"
                  filterable></el-option>
              </el-select>
              <el-select v-else-if="form.TypeRule == 4" class="select-primary" v-model="form.DefaultValue">
                <el-option v-for="option in shippingMethods" :value="option.value" :label="option.name"
                  :key="option.value" filterable></el-option>
              </el-select>
              <el-select v-else-if="form.TypeRule == 5" class="select-primary" v-model="form.DefaultValue">
                <el-option v-for="option in paymentMethods" :value="option.value" :label="option.name"
                  :key="option.value" filterable></el-option>
              </el-select>
              <el-input v-else-if="form.TypeRule == 2" placeholder="Informar ID da utilização no SAP" type="number"
                min="0" v-model="form.DefaultValue"></el-input>
              <el-input v-else v-model="form.DefaultValue" disabled />
            </base-input>
          </div>
          <div class="col-12">
            <base-input>
              <label>Status:</label><br />
              <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="form.Active" :sync="true" />
            </base-input>
          </div>
        </div>
      </card>
      <card class="table-full-width">
        <OrderRuleConditions ref="conditions" :conditions="form.CustomFieldCondition" />
      </card>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { EventBus } from "@/eventBus";
import constants from '@/util/constants';
import OrderRuleConditions from "./OrderRuleConditions.vue";

export default {
  computed: {
    ...mapState("customfield", ["orderRuleCurrent", "paymentMethods", "shippingMethods", "warehouses", "branchs"]),
    ...mapState(["ecommerce"]),
    ...mapState(['realm']),
  },
  components: {
    OrderRuleConditions
  },
  data() {
    return {
      form: {
        Id: null,
        EcommerceName: "",
        FieldType: null,
        DocumentType: null,
        PlatformField: null,
        ErpField: null,
        DefaultValue: null,
        PredefinedText: null,
        CustomFieldCondition: [],
        PropertyCompareERP: null,
        PropertyComparePlataform: null,
        DontCreateFieldOnNullList: null,
        Active: true,
        TypeRule: null,
        Name: null,
      },
      typeOptions: [],
      disabledField: false,
      showModal: false,
      maxChar: 4,
    };
  },
  methods: {
    ...mapActions("customfield", ["getPaymentMethods", "getShippingMethods", "getWarehouses", "getChannels"]),
    save() {
      this.$emit("save");
    },
    validate() {
      if (this.$isNullOrEmpty(this.form.Name)) {
        this.$showError("Nome obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.form.TypeRule)) {
        this.$showError("Regra para obrigatório.");
        return false;
      } else if (this.$isNullOrEmpty(this.form.DefaultValue)) {
        this.$showError("Valor a ser integrado obrigatório.");
        return false;
      }
      else
        return true;
    },
    getValue() {
      return this.form;
    },
    load() {
      this.form = this.orderRuleCurrent;
      if (this.orderRuleCurrent) {
        this.disabledField = true;
      }
    },
    async init() {
      this.loadTypeOptions();
      this.form.ecommerceName = this.ecommerce.Name;
      await this.getPaymentMethods({ pageSize: 100, pageNumber: 1 });
      await this.getShippingMethods({ pageSize: 100, pageNumber: 1 });
      await this.getWarehouses();
      await this.getChannels();
    },
    loadTypeOptions() {
      this.typeOptions = constants.CustomFieldTypeRule.filter(x => x.Key !== 0);
      this.typeOptions.sort((a, b) => {
        if (a.Label < b.Label) {
          return -1;
        }
        if (a.Label > b.Label) {
          return 1;
        }
        return 0;
      });

      if (this.realm !== "proxys")
        this.typeOptions = this.typeOptions.filter(x => x.Key !== 6 && x.Key !== 7);
    },
    showModalCondition() {
      this.showModal = true;
    },
    closeModalCondition() {
      this.showModal = false;
    },
  },
  async mounted() {
    EventBus.$on("loadOrderRule", () => this.load());
    await this.init();
  }
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
