<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h3 slot="header" class="card-title">Editar forma de pagamento</h3>
      <PaymentMethodForm :typeForm="typeForm" ref="form" />
      <div slot="footer" class="col-12 d-flex justify-content-end flex-wrap">
        <div class="col-4">
          <button class="custom-btn-add m-1" @click="$router.push({ name: 'PaymentMethods' })">Voltar</button>
          <button class="custom-btn-save m-1" @click="update">Atualizar</button>
        </div>
      </div>
      <UpdateLog :dataLog="logs" />
    </card>
  </transition>
</template>
<script>
import PaymentMethodForm from "../components/PaymentMethodForm.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import constants from "@/util/constants";
import { EventBus } from "@/eventBus";
import UpdateLog from "../../../components/UpdateLog.vue";

export default {
  components: {
    PaymentMethodForm,
    UpdateLog
  },
  data() {
    return {
      typeForm: constants.typeForm.edition,
    };
  },
  computed: {
    ...mapState(['realm','ecommerce']),
    ...mapState('paymentMethod', ['paymentMethods', 'logs'])
  },
  methods: {
    ...mapActions("paymentMethod", ["updatePaymentMethod", "updateECompayment", "getPaymentMethodLog"]),
    ...mapMutations('paymentMethod', ['SET_PAYMENTMETHOD_CURRENT']),
    async update() {
      let form = this.$refs.form;

      if (!form.isFormValid())
        return;

      let payload = form.prepareFormToSend();
      await this.updatePaymentMethod(payload).then(() => {
        this.$showSuccess("Forma de pagamento atualizada com sucesso");

        if (this.realm === "vulcabras")
          this.updateECompaymentVulcabras(payload);

        this.$router.push({ name: "PaymentMethods" });
      }).catch(error => {
        if (error.response.data)
          this.$showError(error.response.data);
        else
          this.$showError(error);
      });
    },
    updateECompaymentVulcabras: function (payload) {
      var data = {
        EcommerceName: this.ecommerce.Name,
        Code: payload.Name,
        Name: payload.Name,
        U_ACCTCode: payload.Account,
        U_CardIdVT: payload.CreditCardCode,
        U_Payment_Method: payload.PaymentMethodType,
        U_PayMethCod: payload.ErpReference,
        U_Brand: payload.Brand,
        U_SalesChannel: payload.Channel,
        U_EcomPayment: payload.EcommerceReference
      };

      this.updateECompayment(data);
    },
    getPaymentMethodCurrent() {
      let paymentMethodId = this.$route.params.id;
      let paymentMethod = this.paymentMethods.find(a => a.Id === paymentMethodId);
      this.SET_PAYMENTMETHOD_CURRENT(paymentMethod);
      this.getPaymentMethodLog(paymentMethodId);
      EventBus.$emit("PaymentMethodEdition");
    },
  },
  async mounted() {
    this.getPaymentMethodCurrent();
  },
};
</script>
<style>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #00ADB7;
  color: #ffffff !important;
}

.el-button-group>.el-button.is-active,
.el-button-group>.el-button:active,
.el-button-group>.el-button:focus,
.el-button-group>.el-button:hover {
  color: #ffffff !important;
  background-color: #00ADB7 !important;
}

.el-button {
  display: inline-block;
  color: #ffffff;
  background-color: #00ADB7;
}
</style>
