<template>
    <Modal :modalContentClasses="'card'" :showClose="false" :show="show">
        <h4 slot="header" class="card-title">Adicionar SKU</h4>
        <div class="col-12 col-sm-12">
            <base-input>
                <label>SKU:</label>
                <el-input placeholder="Informe o SKU" rows="5" v-model="notificationEmailSku.Sku"></el-input>
            </base-input>
        </div>
        <div class="modal-footer d-flex justify-content-end">
            <el-button type="primary" @click="addSku" class="ml-auto">Adicionar</el-button>
            <el-button @click="closeModal" style="margin-left: 10px;">Cancelar</el-button>
        </div>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal'

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Modal
    },
    data() {
        return {
            notificationEmailSku: {
                Sku: null
            }
        }
    },
    methods: {
        addSku() {
            if (!this.validate())
                return;

            this.$emit("evtAddedCondition", this.notificationEmailSku);
            this.notificationEmailSku = {
                Sku: null
            }
        },
        closeModal() {
            this.notificationEmailSku.Sku = null;
            this.$emit("evtClose");
        },
        validate() {
            if (!this.notificationEmailSku.Sku) {
                this.$showError("Sku obrigatório.");
                return false;
            } else
                return true;
        }
    },
    mounted() {
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    }
}
</script>
