<template>
  <div class="body-card">
    <div align="center">
      <svg
        class="bahn-logo"
          width="150"
          height="48"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 468.75 149.96"
        >
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="21.99"
              y1="128.01"
              x2="128.06"
              y2="21.95"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stop-color="#00ad9f" />
              <stop offset="1" stop-color="#00fff5" />
            </linearGradient>
          </defs>
          <!-- <title>Bahn_5</title> -->
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <ellipse
                class="cls-1"
                cx="75.02"
                cy="74.98"
                rx="75.02"
                ry="74.98"
              />
              <path
                class="cls-2"
                d="M86.77,47.1a2.61,2.61,0,0,1,3.38-3.63l17.24,26.85c2,2,2,6.56.5,8.57L90.15,105.74a2.61,2.61,0,0,1-3.38-3.63L99.49,78.79a8.7,8.7,0,0,0,0-8.37Z"
              />
              <path
                class="cls-2"
                d="M46.44,47.1a2.59,2.59,0,0,1-.32-1.26,2.63,2.63,0,0,1,.7-1.78,2.6,2.6,0,0,1,3-.59L67.06,70.32c2,2,2,6.56.51,8.57L49.82,105.74a2.61,2.61,0,0,1-3.38-3.63L59.17,78.79a8.76,8.76,0,0,0,0-8.37Z"
              />
              <path
                class="cls-2"
                d="M66.6,47.1a2.71,2.71,0,0,1-.32-1.26A2.62,2.62,0,0,1,70,43.47L87.23,70.32c2,2,2,6.56.5,8.57L70,105.74a2.62,2.62,0,0,1-3.39-3.63L79.33,78.79a8.76,8.76,0,0,0,0-8.37Z"
              />
              <path
                class="cls-2 name"
                d="M240.11,94c0,15.93-11.6,26.6-26.49,26.6H180.54V28.35h26.88c16.08,0,25.57,8.3,25.57,21.87,0,11.19-5.27,17.12-9.75,20C234.31,74.58,240.11,83,240.11,94ZM193.45,40.21v24.5h15.29c6.46,0,11.34-6.59,11.34-12.65,0-6.72-3.56-11.85-12.66-11.85ZM227.19,92c0-7.51-5.14-14.49-14.76-14.49h-19V108.7h20C221.39,108.7,227.19,101.32,227.19,92Z"
              />
              <path
                class="cls-2 name"
                d="M253.28,87.62a33.94,33.94,0,1,1,67.87,0v32.93H308.24V110.14c-3.17,6.46-11.6,11.46-21,11.46A34,34,0,0,1,253.28,87.62Zm55.09,0c0-12-8.83-21.47-20.95-21.47S266.2,75.64,266.2,87.62a21.09,21.09,0,0,0,21.22,21.47C299.41,109.09,308.37,99.61,308.37,87.62Z"
              />
              <path
                class="cls-2 name"
                d="M395,85.91v34.64H382V85.91c0-13.43-5.79-19.76-16.07-19.76s-16.08,6.33-16.08,19.5v34.9H337V28.35h12.92V60.62c4.22-5.27,9.62-7,16.08-7C382.83,53.64,395,63.78,395,85.91Z"
              />
              <path
                class="cls-2 name"
                d="M468.75,120.55H455.83V85.91c0-13.43-5.8-19.76-16.08-19.76s-16.07,6.33-16.07,19.76v34.64H410.76V85.91c0-22.13,12.13-32.27,29-32.27s29,10.14,29,32.27Z"
              />
            </g>
          </g>
        </svg>
    </div>
    <div class="container">
      <div class="">
        <Welcome />
      </div>
      <div class="pb-4" align="center">
        <button class="custom-btn-filter" style="margin-top: 12px;margin-left:4px" @click="save">Salvar</button>
      </div>
    </div>
    <SettingsLoadingModal ref="searching" :show="modalShow"/>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import EcommerceSelect from '../components/Ecommerce.vue';
import Welcome from '../components/Welcome.vue';
import { EventBus } from "@/eventBus";
import SettingsLoadingModal from "../components/SettingsLoadingModal.vue";

export default {
  components: {
    Welcome,
    EcommerceSelect,
    SettingsLoadingModal
  },
  data() {
    return {
      active: 0,
      showBack: false,
      modalShow: false
    };
  },
  computed: {
    ...mapState("wizard",["ecommerces"])
  },
  methods: {
    save(){
      EventBus.$emit("saveEcommerce");
    },
  },
  created() {
    let docClasses = document.body.classList;
    docClasses.add('white-content');
    docClasses.add('wizard');
  },
}
</script>
<style scoped>
.bahn-logo .name {
  fill: #1e2543 !important;
}
.body-card{
  width: 990px;
  position: relative;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 3px 9.5px 0.5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 3px 9.5px 0.5px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 3px 9.5px 0.5px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 3px 9.5px 0.5px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 3px 9.5px 0.5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
}
</style>
