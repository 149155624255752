<template>
    <transition name="fade" mode="out-in">
        <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">Grupos</h4>
            <div>
                <div class="row" style="float: right">
                    <span style="margin: 0 10pt">
                        <button class="custom-btn-add" @click="addNew">Novo</button>
                    </span>
                </div>
                <el-table :data="groups">
                    <el-table-column prop="id" label="Id" align="center"></el-table-column>
                    <el-table-column prop="name" label="Grupo" align="center"></el-table-column>
                    <el-table-column align="center" label="Ações">
                        <template #default="props">
                            <el-tooltip v-if="['admin', 'default_group'].includes(props.row.name)" effect="light" :open-delay="300"
                                content="Não é possível editar este grupo. Crie um grupo personalizado."
                                placement="top">
                                <span>
                                    <base-button class="like !important btn-link" type="primary" size="sm" icon
                                        :disabled="true">
                                        <i class="material-icons">mode_edit_outline</i>
                                    </base-button>
                                </span>
                            </el-tooltip>
                            <base-button v-else class="like !important btn-link" type="primary" size="sm" icon
                                @click="detail(props.row.id)">
                                <i class="material-icons">mode_edit_outline</i>
                            </base-button>
                            <base-button v-if="!['admin', 'default_group'].includes(props.row.name)" class="edit btn-link" type="danger" size="sm" icon
                                @click="remove(props.row.id)">
                                <i class="material-icons">delete_outline</i>
                            </base-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-between flex-wrap">
                <div class="col-5"></div>
                <div class="col-2">
                    <label>
                        Exibindo
                        <span class="primary-text">{{ from + 1 }}</span> -
                        <span class="primary-text">{{ to }}</span> de
                        <span class="primary-text">{{ total }}</span> registros
                    </label>
                </div>
                <div class="col-3">
                    <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                        :per-page="pagination.perPage" :total="total" @input="loadGroups"></base-pagination>
                </div>
                <div class="col-2">
                    <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
                        v-model="pagination.perPage" placeholder="Per page">
                        <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item"
                            :label="item" :value="item"></el-option>
                    </el-select>
                </div>
            </div>
        </card>
    </transition>
</template>
<script>
import swal from "sweetalert2";
import { BasePagination, BaseRadio } from "src/components";
import { mapActions, mapState } from 'vuex';

export default {
    components: {
        BaseRadio,
        BasePagination,
    },
    data() {
        return {
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
                first: 0
            },
            param: {
                id: null,
                realm: null,
                clientId: null
            }
        };
    },
    computed: {
        ...mapState('group', ['groups', 'groupCount']),
        ...mapState(["ecommerce"]),
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.groupCount;
        },
    },
    methods: {
        ...mapActions('group', ['getGroups', 'getGroupsCount', 'removeGroup', 'getRoles', 'getClientId', 'getClientRole', 'getGroupPermissions', 'getGroupClientPermissions']),
        async loadGroupsCount() {
            this.param.realm = this.$store.state.realm;
            let params = Object.assign(this.param, this.pagination);
            this.getGroupsCount(params);
        },
        async loadGroups() {
            this.param.realm = this.$store.state.realm;
            this.pagination.first = this.from;
            let params = Object.assign(this.param, this.pagination, { ecommerceName: this.ecommerce.Name });
            this.getGroups(params);
            this.loadGroupsCount();
            this.getRoles(params);
            let id = await this.getClientId(params);
            if (!this.$isNullOrEmpty(id)) {
                this.param.clientId = id;
                params = Object.assign(this.param);
                await this.getClientRole(params);
            }
        },
        clearFilter() {
            this.filters.name = null;
            this.filters.type = null;
            this.loadGroups();
        },
        async detail(id) {
            this.param.id = id;
            let params = Object.assign(this.param);
            await this.getGroupClientPermissions(params).then(async () => {
                await this.getGroupPermissions(id).then(() => {
                    this.$router.push({ name: "GroupDetail", query: { id: id, realm: this.$store.state.realm } })
                });
            });
        },
        addNew() {
            this.$router.push({ name: "GroupCreate", query: { realm: this.$store.state.realm } })
        },
        remove(id) {
            swal({
                title: "Gostaria de excluir o registro?",
                text: "Esse processo não poderá ser revertido.",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                cancelButtonText: "Não",
                confirmButtonText: "Sim",
            }).then(async (result) => {
                if (result.value) {
                    await this.delete(id)
                    await this.loadGroups();
                }
            });
        },
        loadGroupPermissions(id) {
            this.param.id = id;
            this.param.realm = this.$store.state.realm;
            let params = Object.assign(this.param);
            this.getGroupPermissions(params);
        },
        async delete(id) {
            try {
                this.param.id = id;
                this.param.realm = this.$store.state.realm;
                let payload = Object.assign(this.param);
                await this.removeGroup(payload);
                this.$showSuccess("O cadastro foi excluido com sucesso");
            } catch (error) {
                this.$showError("Ocorreu um erro ao excluir o cadastro");
            }
        },
    },
    async mounted() {
        await this.loadGroupsCount();
        await this.loadGroups();
    },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
    {
    opacity: 0;
}

textarea {
    background: transparent !important;
    border-color: #2b3553 !important;
}

.el-textarea__inner:focus {
    outline: 0;
    border-color: #00ADB7 !important;
}

.el-textarea__inner:hover {
    border-color: #00ADB7 !important;
}
</style>
