<template>
  <div class="input-select" style="width: 100%; padding-right:10px; overflow: auto">
    <div v-for="(item, index) in configurations" :key="index">
      <div v-if="item.FieldType === ConfigurationFieldType.Combobox" :id="getIdForWizard(item)" class="table-responsive"
        style="padding-top: 5px; width: 100%">
        <div class="d-flex align-items-center">
          <label>{{ item.Alias }}</label>
          <el-tooltip v-if="item.Description != undefined" :content="item.Description" effect="light" :open-delay="100"
            placement="top">
            <i class="material-icons ml-1 mb-2 icon-custom">info_outline</i>
          </el-tooltip>
        </div>
        <base-input :select="true" v-if="showOnlyForSAPHANA(item.Key)" v-model="item.Value"
          @change="handleChange($event, item.Key)">
          <template v-slot:options>
            <el-option v-for="(option, index) in item.ConfigurationOptions" :value="option.Value" :label="option.Label"
              :key="index"></el-option>
          </template>
        </base-input>
        <base-input class="input-card-grande" v-model="item.Value" @change="handleChange($event, item.Key)" v-else>
        </base-input>
      </div>
      <div v-if="item.FieldType === ConfigurationFieldType.Text" :id="getIdForWizard(item)" class="table-responsive"
        style="padding-top: 5px; width: 100%">
        <div class="d-flex align-items-center">
          <label>{{ item.Alias }}</label>
          <el-tooltip v-if="item.Description != undefined" :content="item.Description" effect="light" :open-delay="100"
            placement="top">
            <i class="material-icons ml-1 mb-2 icon-custom">info_outline</i>
          </el-tooltip>
        </div>
        <input v-model="item.Value" @change="handleChange($event, item.Key)" class="form-control" />
      </div>
      <div v-if="item.FieldType === ConfigurationFieldType.Toggle" :id="getIdForWizard(item)"
        class="table-responsive d-flex" style="padding-top: 20px; width: 100%">
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
          v-model="item.Value" @change="customValidationForIntegrateTaxFields(item.Key, $event)" />
        <div class="d-flex align-items-center ml-2">
          <label>{{ item.Alias }}</label>

          <el-tooltip v-if="item.Description != undefined" :content="item.Description" effect="light" :open-delay="100"
            placement="top">
            <i class="material-icons ml-1 mb-2 icon-custom">info_outline</i>
          </el-tooltip>
        </div>
      </div>
      <div v-if="item.FieldType === ConfigurationFieldType.DatePicker" :id="getIdForWizard(item)"
        class="table-responsive" style="padding-top: 20px">
        <div class="d-flex align-items-center">
          <label>{{ item.Alias }}</label>

          <el-tooltip v-if="item.Description != undefined" :content="item.Description" effect="light" :open-delay="100"
            placement="top">
            <i class="material-icons ml-1 mb-2 icon-custom">info_outline</i>
          </el-tooltip>
        </div>
        <br>
        <div>
          <base-input style="width:25%">
            <input type="date" class="form-control" @change="handleChange($event, item.Key)" v-model="item.Value" />
          </base-input>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import constants from "@/util/constants";
import { EventBus } from "@/eventBus";

export default {
  props: {
    isSAPHANA: {
      type: Boolean,
    },
    group: {
      type: Number,
    },
  },
  data() {
    return {
      slot: "default",
      configurations: [],
      configurationsChanged: [],
      ConfigurationFieldType: constants.ConfigurationFieldType,
    };
  },
  computed: {
    ...mapState("configurations", ["configurationGroup"]),
    ...mapState(["realm"]),
    ...mapGetters("configurations", ["getConfigurationByGroup"]),
    showBPL_IDAssignedToInvoice() {
      return this.realm !== "spaturbousa";
    },
  },
  methods: {
    handleChange(newValue, key) {
      this.configurations.forEach((c) => {
        if (c.Key === key) {
          this.addConfigurationChanged(c);

          if (c.FieldType === constants.ConfigurationFieldType.Combobox && this.isSAPHANA)
            c.Value = newValue;
        }
      });
    },
    getIdForWizard(item) {
      switch (item.Key) {
        case "BPL_IDAssignedToInvoice":
          return "v-step-1";
        case "IntegrationPrice":
          return "v-step-2";
        case "PriceListName":
          return "v-step-3";
        case "TaxFreightProductIntegrated":
          return "v-step-4";
        case "IntelligentSafetyStockQuantity":
          return "v-step-5";
        case "SafetyStockQuantity":
          return "v-step-6";
        case "StockUpdateForOrder":
          return "v-step-7";
        case "MultipleWarehouses":
          return "v-step-8";
        case "GroupCode":
          return "v-step-9";
        case "SellerName":
          return "v-step-10";
        case "Series":
          return "v-step-11";
        case "LanguageCode":
          return "v-step-12";
        case "Currency":
          return "v-step-13";
        case "ERPCountryOrigin":
          return "v-step-14";
        case "IntegrateWithERP":
          return "v-step-15";
        default:
          return "";
      }
    },
    addConfigurationChanged(configuration) {
      if (!this.configurationsChanged.some(c => c.Key === configuration.Key)) {
        this.configurationsChanged.push(configuration);
      }
    },
    fillConfigurations() {
      this.slot = this.isSAPHANA === true ? "default" : "label";
      this.configurations = this.getConfigurationByGroup(this.group);
      this.configurations.forEach((configuration) => {
        configuration.Value = this.$getConfigurationValueByType(configuration);
      });
    },
    showOnlyForSAPHANA(config) {
      switch (config) {
        case constants.Configurations.BPL_IDAssignedToInvoice:
          return !this.isSAPHANA ? false : true;
        case constants.Configurations.ExpenseCode:
          return !this.isSAPHANA ? false : true;
        case constants.Configurations.SellerName:
          return !this.isSAPHANA ? false : true;
        case constants.Configurations.PriceListName:
          return !this.isSAPHANA ? false : true;
        case constants.Configurations.PriceListNameDiscount:
          return !this.isSAPHANA ? false : true;
        case constants.Configurations.PriceListNameB2B:
          return !this.isSAPHANA ? false : true;
        case constants.Configurations.GroupCode:
          return !this.isSAPHANA ? false : true;
        case constants.Configurations.Series:
          return !this.isSAPHANA ? false : true;
        default:
          return true;
      }
    },
    customValidationForIntegrateTaxFields(key, event) {

      this.configurations.forEach((c) => {
        if (c.Key === key)
          this.addConfigurationChanged(c);
      });

      let isTaxProductIntegrated = key === constants.Configurations.TaxProductIntegrated;
      let isTaxFreightProductIntegrated =
        key === constants.Configurations.TaxFreightProductIntegrated;

      if (
        isTaxProductIntegrated &&
        event.value === true &&
        this.configurations.some(
          (c) =>
            c.Key === constants.Configurations.TaxFreightProductIntegrated &&
            c.Value === true
        )
      ) {
        this.$showError("Favor selecionar apenas uma opção de integração de Imposto");
        this.configurations.forEach((c) => {
          if (c.Key === constants.Configurations.TaxProductIntegrated) c.Value = false;
        });
      }

      if (
        isTaxFreightProductIntegrated &&
        event.value === true &&
        this.configurations.some(
          (c) =>
            c.Key === constants.Configurations.TaxProductIntegrated && c.Value === true
        )
      ) {
        this.$showError("Favor selecionar apenas uma opção de integração de Imposto");
        this.configurations.forEach((c) => {
          if (c.Key === constants.Configurations.TaxFreightProductIntegrated)
            c.Value = false;
        });
      }
    },
    getValue() {
      return this.configurationsChanged;
    },

  },
  async mounted() {
    EventBus.$on("fillConfigurations", () => this.fillConfigurations());
  },
};
</script>
<style lang="css">
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Inverte as cores, pode ajustar conforme necessário */
}
</style>
