<template>
    <div class="row">
        <div class="col-6">
            <card type="tasks" class="stacked-form">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <base-input>
                                <label>ID:</label>
                                <el-input placeholder="Id" v-model="group.id" disabled>
                                </el-input>
                            </base-input>
                        </div>
                        <div class="col-12">
                            <base-input>
                                <label>Grupo:</label>
                                <el-input placeholder="Nome de usuário" v-model="group.name" :disabled="disabledField">
                                </el-input>
                            </base-input>
                        </div>
                    </div>
                </div>
            </card>
        </div>
        <div class="col-6" v-if="disabledField">
            <card>
                <h4 slot="header" class="card-title">Permissões do usuário</h4>
                <h4>Minha Conta</h4>
                <el-table :data="childrensAccount" style="width: 100%" :show-header="true">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermissionClient(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
                <br />
                <h4>Pedidos</h4>
                <el-table :data="childrensOrder" style="width: 100%" :show-header="true">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
                <br />
                <h4>Produtos</h4>
                <el-table :data="childrensProduct" :show-header="true" style="width: 100%">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
                <br />
                <h4>Histórico</h4>
                <el-table :data="childrensHistory" :show-header="true" style="width: 100%">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
                <h4>Ponte de Comando</h4>
                <el-table :data="childrensCommandbridge" style="width: 100%" :show-header="true">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
                <br />
                <h4>Regras de Pedido</h4>
                <el-table :data="childrensCustomFields" :show-header="true" style="width: 100%">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
                <h4>Definições de E-commerce</h4>
                <el-table :data="childrensEcommerceDefinitions" :show-header="true" style="width: 100%">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
                <h4>Definições de Integração</h4>
                <el-table :data="childrensIntegrationDefinitions" :show-header="true" style="width: 100%">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
                <h4>Definições de Serviços</h4>
                <el-table :data="childrensServiceDefinitions" :show-header="true" style="width: 100%">
                    <el-table-column prop="name" label="-" min-width="150" />
                    <el-table-column prop="create" label="Leitura" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasReadPermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasReadDisabled" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="read" label="Criação" align="center" size="small">
                        <template slot-scope="props">
                            <el-checkbox v-model="props.row.hasCreatePermission" @change="updatePermission(props.row)"
                                :disabled="props.row.hasCreateDisabled" />
                        </template>
                    </el-table-column>
                </el-table>
            </card>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { EventBus } from "@/eventBus";

export default {
    computed: {
        ...mapState('group', ['groupCurrent', 'groupPermissions', 'groupClientsPermissions', 'roles', 'clientRole']),
        ...mapState(['ecommerce']),
    },
    data() {
        return {
            group: {
                id: null,
                name: "",
                roles: [{
                    id: null,
                    name: null,
                    active: null
                }],
            },
            updatedPermissions: [],
            updatedClients: [],
            differences: [],
            childrensAccount: [
                {
                    name: 'Minha Conta',
                    create: 'manage-users',
                    read: 'none',
                    hasCreatePermission: true,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: true,
                },
                {
                    name: 'Dados Restritos',
                    create: 'none',
                    read: '45_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                }
            ],
            childrensOrder: [
                {
                    name: 'Pedidos',
                    create: '29_c',
                    read: '29_r',
                    update: '29_c',
                    delete: '29_c',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Pedidos Fulfillment',
                    create: '11_c',
                    read: '11_r',
                    update: '11_c',
                    delete: '11_c',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                }
            ],
            childrensProduct: [
                {
                    name: 'Mapeamento',
                    create: '22_c',
                    read: '22_r',
                    update: '22_c',
                    delete: '22_c',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Importar Mapeamento',
                    create: '15_c',
                    read: '15_r',
                    update: '15_c',
                    delete: '15_c',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Exportar Imagens',
                    create: '10_c',
                    read: '10_r',
                    update: '10_c',
                    delete: '10_c',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Atualização Manual',
                    create: '21_c',
                    read: '21_r',
                    update: '21_c',
                    delete: '21_c',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                }
            ],
            childrensHistory: [
                {
                    name: 'Captação Manual',
                    create: '20_r',
                    read: '20_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Estoque',
                    create: '41_c',
                    read: '41_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Preço',
                    create: '33_c',
                    read: '33_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Pedido',
                    create: '18_r',
                    read: '18_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Ações de usuário',
                    create: '17_r',
                    read: '17_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Log de Integração',
                    create: '16_c',
                    read: '16_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                }
            ],
            childrensCommandbridge: [
                {
                    name: 'Ponte de comando',
                    create: 'none',
                    read: '04_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                }
            ],
            childrensCustomFields: [
                {
                    name: 'Regras de Pedido',
                    create: '30_c',
                    read: '30_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Campos Customizados',
                    create: '08_c',
                    read: '08_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: true,
                }
            ],
            childrensEcommerceDefinitions: [
                {
                    name: 'E-commerce',
                    create: '09_c',
                    read: '09_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Status',
                    create: '40_c',
                    read: '40_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Modalidade de Envio',
                    create: '39_c',
                    read: '39_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Marca',
                    create: '03_c',
                    read: '03_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                }
            ],
            childrensIntegrationDefinitions: [
                {
                    name: 'Autenticações',
                    create: '02_c',
                    read: '02_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Addons',
                    create: '01_c',
                    read: '01_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Mapeamentos ERP',
                    create: '',
                    read: '24',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Geral',
                    create: '13_c',
                    read: '13_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Utilização',
                    create: '43_c',
                    read: '43_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Depósitos',
                    create: '44_c',
                    read: '44_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Lista de preço',
                    create: '34_c',
                    read: '34_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Métodos de envio',
                    create: '38_c',
                    read: '38_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Formas de pagamento',
                    create: '31_c',
                    read: '31_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Vendedores',
                    create: '37_c',
                    read: '37_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Grupo de Clientes',
                    create: '14_c',
                    read: '14_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Endereços',
                    create: '06_c',
                    read: '06_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Fulfillment',
                    create: '12_c',
                    read: '12_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Eventos',
                    create: '07_c',
                    read: '07_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'CostCenter',
                    create: '05_c',
                    read: '05_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Documentos',
                    create: '',
                    read: '23',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Documentos ERP',
                    create: '19_c',
                    read: '19_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Observação',
                    create: '27_c',
                    read: '27_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                },
                {
                    name: 'Canais de Venda',
                    create: '35_c',
                    read: '35_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: false,
                    hasReadDisabled: false,
                }
            ],
            childrensServiceDefinitions: [
                {
                    name: 'Agendamentos',
                    create: '36_c',
                    read: '36_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                },
                {
                    name: 'Notificações',
                    create: '26_c',
                    read: '26_r',
                    hasCreatePermission: false,
                    hasReadPermission: false,
                    hasCreateDisabled: true,
                    hasReadDisabled: false,
                }
            ],
            datagroupPermissionsAdd: [
                {
                    id: null,
                    name: null
                }
            ],
            datagroupPermissionsRemove: [
                {
                    id: null,
                    name: null
                }
            ],
            disabledField: false,
            param: {
                id: null,
                IdPermission: null,
                name: null,
                realm: null,
                containerId: null,
                bodyAdd: [{
                    id: null,
                    name: null
                }],
                bodyRemove: [{
                    id: null,
                    name: null
                }],
            }
        }
    },
    methods: {
        save() {
            this.$emit("save");
        },
        validate() {
            return true;
        },
        getValue() {
            if (!this.group.roles) {
                this.group.roles = [];
            }
            const groupRoles = [];
            const groupClients = [];

            this.updatedPermissions.forEach(group => {
                group.permissions.forEach(permission => {
                    const item = this.groupPermissions.find(role => role.name === permission.name);
                    if ((item && !permission.action) || (!item && permission.action)) {
                        groupRoles.push({
                            id: permission.id,
                            name: permission.name,
                            active: permission.action,
                            description: permission.description
                        });
                    }
                })
            });

            this.updatedClients.forEach(group => {
                group.clients.forEach(permission => {
                    const item = this.groupClientsPermissions.find(role => role.name === permission.name);
                    if ((item && !permission.action) || (!item && permission.action)) {
                        groupClients.push({
                            id: permission.id,
                            name: permission.name,
                            active: permission.action,
                            description: permission.description,
                            containerId: permission.containerId
                        });
                    }
                })
            });

            return {
                ID: this.group.id,
                name: this.group.name,
                roles: groupRoles,
                clients: groupClients,
            };
        },
        loadGroup() {
            this.getGroupPermissions();
            this.getGroupClientsPermissions();
            this.group = this.groupCurrent;
            if (this.groupCurrent) {
                this.disabledField = true;
            }
        },
        getGroupPermissions() {
            if (!Array.isArray(this.groupPermissions)) return;

            const setPermissions = (item) => {
                const createPermission = this.groupPermissions.filter(permission => permission.name === item.create);
                item.hasCreatePermission = createPermission.length > 0 ? true : false;
                const readPermission = this.groupPermissions.filter(permission => permission.name === item.read);
                item.hasReadPermission = readPermission.length > 0 ? true : false;
                const updatePermission = this.groupPermissions.filter(permission => permission.name === item.update);
                item.hasUpdatePermission = updatePermission.length > 0 ? true : false;
                const deletePermission = this.groupPermissions.filter(permission => permission.name === item.delete);
                item.hasDeletePermission = deletePermission.length > 0 ? true : false;
            }

            const arraysToCheck = [this.childrensOrder, this.childrensProduct, this.childrensHistory, this.childrensCommandbridge, this.childrensCustomFields, this.childrensEcommerceDefinitions, this.childrensIntegrationDefinitions, this.childrensServiceDefinitions];

            arraysToCheck.forEach(arr => arr.forEach(setPermissions));
        },
        getGroupClientsPermissions() {
            if (!Array.isArray(this.groupClientsPermissions)) return;

            const setPermissions = (item) => {
                const updatePermission = this.groupClientsPermissions.filter(permission => permission.name === item.update);
                item.hasUpdatePermission = updatePermission.length > 0 ? true : false;
                const readPermission = this.groupPermissions.filter(permission => permission.name === item.read);
                item.hasReadPermission = readPermission.length > 0 ? true : false;
            }

            const arraysToCheck = [this.childrensAccount];

            arraysToCheck.forEach(arr => arr.forEach(setPermissions));
        },
        updatePermission: function (item) {
            const updatedItem = {
                permissions: []
            };

            const createPermission = this.roles.find(permission => permission.name === item.create) || {};
            updatedItem.permissions.push({ id: createPermission.id, name: createPermission.name, action: item.hasCreatePermission, description: `${item.name} - Criação`, tag: item.name });

            const readPermission = this.roles.find(permission => permission.name === item.read) || {};
            updatedItem.permissions.push({ id: readPermission.id, name: readPermission.name, action: item.hasReadPermission, description: `${item.name} - Leitura`, tag: item.name });

            const updatePermission = this.roles.find(permission => permission.name === item.update) || {};
            updatedItem.permissions.push({ id: updatePermission.id, name: updatePermission.name, action: item.hasUpdatePermission, description: `${item.name} - Alteração`, tag: item.name });

            const deletePermission = this.roles.find(permission => permission.name === item.delete) || {};
            updatedItem.permissions.push({ id: deletePermission.id, name: deletePermission.name, action: item.hasDeletePermission, description: `${item.name} - Exclusão`, tag: item.name });

            const index = this.updatedPermissions.findIndex(p => p.permissions.some(permission => permission.tag === item.name));

            if (index !== -1)
                this.updatedPermissions.splice(index, 1);

            if (updatedItem.permissions.every(permission => permission.id !== null))
                this.updatedPermissions.push(updatedItem);
        },
        updatePermissionClient: async function (item) {
            const updatedClient = {
                clients: []
            };

            const updatePermission = this.clientRole || {};
            updatedClient.clients.push({
                id: updatePermission.id,
                name: updatePermission.name,
                action: item.hasUpdatePermission,
                description: `${item.name} - Alteração`,
                containerId: this.clientRole.containerId,
                tag: item.name
            });

            const index = this.updatedClients.findIndex(p => p.name === item.name);

            if (index !== -1)
                this.updatedClients.splice(index, 1, updatedClient);

            if (updatedClient.clients.every(clients => clients.id !== null))
                this.updatedClients.push(updatedClient);
        }
    },
    mounted() {
        EventBus.$on("loadGroup", () => this.loadGroup());
    }
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>
