<template>
    <div class="row">
        <div class="col-6">
            <card type="tasks" class="stacked-form">
                <div class="col-12">
                    <div class="row">
                        <div class="col-6">
                            <base-input>
                                <label>Documento:</label>
                                <el-select v-model="observationDocument.DocumentType" placeholder="Selecione"
                                    :disabled="disabledField" @change="cleanFields">
                                    <el-option v-for="item in documentOptions" :key="item.value" :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-6">
                            <base-input>
                                <label>Tipo de Observação:</label>
                                <el-select v-model="observationDocument.ObservationType" placeholder="Selecione">
                                    <div v-if="[1, 2, 4, 5, 6, 11, 12].includes(observationDocument.DocumentType)">
                                        <el-option v-for="item in documentTypeOptions" :key="item.value"
                                            :label="item.label" :value="item.value">
                                        </el-option>
                                    </div>
                                    <div v-else-if="[8].includes(observationDocument.DocumentType)">
                                        <el-option v-for="item in documentTypeOrdersOptions" :key="item.value"
                                            :label="item.label" :value="item.value">
                                        </el-option>
                                    </div>
                                    <div v-else>
                                        <el-option v-for="item in documentTypeIncomingPaymentsOptions" :key="item.value"
                                            :label="item.label" :value="item.value">
                                        </el-option>
                                    </div>
                                </el-select>
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <base-input>
                                <label>Observação:</label>
                                <el-input type="textarea" placeholder="Informar a Observação" v-bind:maxlength="maxChar"
                                    v-model="observationDocument.Observation">
                                </el-input>
                                <small class="text-white-50">{{ charactersLeft }} caracteres restantes</small>
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <base-input>
                                <label>Status:</label><br />
                                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input
                                    type="checkbox" v-model="observationDocument.Active" :sync="true" />
                            </base-input>
                        </div>
                    </div>
                    <div class="row">
                        <card type="tasks" class="stacked-form">
                            <h4 class="card-title">Condições
                                <base-button type="primary" small class="btn-next float-right"
                                    @click="showModalConditions">
                                    Adicionar
                                </base-button>
                            </h4>
                            <div class="col-12">
                                <el-table :data="observationDocument.ObservationDocumentConditions">
                                    <el-table-column label="Condição" align="center">
                                        <div slot-scope="props">
                                            {{ getDocumentCondition(props.row.DocumentCondition) }}
                                        </div>
                                    </el-table-column>
                                    <el-table-column label="Tipo da condição" align="center">
                                        <div slot-scope="props">
                                            {{ getDocumentConditionType(props.row.ConditionType) }}
                                        </div>
                                    </el-table-column>
                                    <el-table-column label="Valor da Condição" min-width="100" prop="ConditionValue">
                                    </el-table-column>
                                    <el-table-column :min-width="135" align="right" label="Ações">
                                        <div slot-scope="props">
                                            <base-button class="like !important btn-link" type="danger" size="sm" icon
                                                @click="Delete(props.row)">
                                                <i class="material-icons">delete_outline</i>
                                            </base-button>
                                        </div>
                                    </el-table-column>
                                </el-table>
                            </div>
                            <ObservationDocumentConditionsVue :observationDocument="observationDocument"
                                :show="showModal" @evtAddedCondition="saveCondition" @evtClose="closeModalCondition" />
                        </card>
                    </div>
                </div>
            </card>
        </div>
        <div class="col-6">
            <card type="tasks" class="stacked-form">
                <h4 class="card-title">Legenda</h4>
                <div class="col-12">
                    <el-table :data="tagsData">
                        <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
                            :prop="column.prop" :label="column.label" align="center"></el-table-column>
                    </el-table>
                </div>
                <br /><br />
                <h5><strong>* Utilize a TAG para personalizar a observação do documento</strong></h5>
            </card>
        </div>
    </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import swal from "sweetalert2";
import { mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import ObservationDocumentConditionsVue from './ObservationDocumentConditions.vue';
import constants from '../../../util/constants';

export default {
    components: {
        ObservationDocumentConditionsVue,
        VueEditor
    },
    computed: {
        ...mapState('observationDocument', ['observationDocumentCurrent']),
        ...mapState(['ecommerce']),
        charactersLeft() {
            return this.maxChar - this.observationDocument.Observation.length;
        }
    },
    data() {
        return {
            maxChar: 254,
            observationDocument: {
                EcommerceName: "",
                Active: false,
                Observation: "",
                DocumentType: null,
                ObservationType: null,
                ObservationDocumentConditions: []
            },
            statusOptions: [
                { value: false, label: 'Inativo' },
                { value: true, label: 'Ativo' }
            ],
            documentOptions: [
                { value: 1, label: 'Adiantamento' },
                { value: 2, label: 'Nota Fiscal de Saída' },
                { value: 3, label: 'Contas a Receber' },
                { value: 4, label: 'Transferência - Recebimento de Mercadorias' },
                { value: 5, label: 'Transferência - Nota Fiscal de Entrada' },
                { value: 6, label: 'Transferência - Nota Fiscal de Saída' },
                { value: 7, label: 'Contas a Receber (Adiantamento)' },
                { value: 8, label: 'Pedido de Venda' },
                { value: 11, label: 'Fulfillment - Dev. Nota Fiscal de Saída' },
                { value: 12, label: 'Fulfillment - Nota Fiscal de Saída' }
            ],
            documentTypeOptions: [
                { value: 1, label: 'Observação de abertura' },
                { value: 2, label: 'Observação de encerramento' },
                { value: 3, label: 'Observação do diário' },
                { value: 5, label: 'Observação' }
            ],
            documentTypeIncomingPaymentsOptions: [
                { value: 4, label: 'Observação do diário' }
            ],
            documentTypeOrdersOptions: [
                { value: 5, label: 'Observação' }
            ],
            tableColumns: [
                {
                    prop: "Tag",
                    label: "Tag",
                    minWidth: 100,
                },
                {
                    prop: "Description",
                    label: "Descrição",
                    minWidth: 100,
                }
            ],
            tagsData: [
                {
                    Tag: '@businessPartners',
                    Description: 'Nome do Parceiro de Negócios'
                },
                {
                    Tag: '@sequenceSerial',
                    Description: 'N° da Nota Fiscal de Saída'
                },
                {
                    Tag: '@sapOrderNumber',
                    Description: 'Nº do pedido no SAP B1'
                },
                {
                    Tag: '@orderNumber',
                    Description: 'Nº do pedido na origem'
                },
                {
                    Tag: '@shippingMethod',
                    Description: 'Tipo de Envio do pedido'
                }
            ],
            disabledField: false,
            showModal: false
        }
    },

    methods: {
        save() {
            this.$emit("save");
        },
        validate() {
            if (this.$isNullOrEmpty(this.observationDocument.Observation)) {
                this.$showError("Observação obrigatória.");
                return false;
            } else if (this.$isNullOrEmpty(this.observationDocument.DocumentType)) {
                this.$showError("Documento obrigatório.");
                return false;
            } else if (this.$isNullOrEmpty(this.observationDocument.ObservationType)) {
                this.$showError("Tipo de observação obrigatório.");
                return false;
            } else
                return true;
        },
        getValue() {
            return this.observationDocument;
        },
        load() {
            this.observationDocument = this.observationDocumentCurrent;
            if (this.observationDocumentCurrent) {
                this.disabledField = true;
            }
        },
        cleanFields() {
            this.observationDocument.ObservationType = "";
        },
        showModalConditions() {
            this.showModal = true;
        },
        saveCondition(model) {
            this.observationDocument.ObservationDocumentConditions.push(model);
            this.showModal = false;
        },
        closeModalCondition() {
            this.showModal = false;
        },
        Delete: function (object) {
            swal({
                title: "Gostaria de excluir o cadastro?",
                text: "Esse processo não poderá ser revertido.",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                cancelButtonText: "Não",
                confirmButtonText: "Sim",
            }).then(async (result) => {
                if (result.value) {
                    await this.remove(object)
                }
            });
        },
        async remove(object) {
            var pos = this.observationDocument.ObservationDocumentConditions.indexOf(object);
            this.observationDocument.ObservationDocumentConditions.splice(pos, 1);
        },
        getDocumentCondition(value) {
            return constants.ConditionOptions.find(a => a.value === value).label;
        },
        getDocumentConditionType(value) {
            return constants.ConditionTypeOptions.find(a => a.value === value).label;
        }
    },
    mounted() {
        EventBus.$on("loadObservationDocument", () => this.load());
        this.documentOptions.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
    },
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>
