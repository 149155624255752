<template>
  <div class="row">
    <SettingsLoadingModal ref="searching" :show="modalShow" @evtCloseModal="closeModal" />
    <div class="col-12">
      <card type="tasks">
        <div class="mt-1 row">
          <div class="d-flex col-md-6 justify-content-start">
            <h4 data-v-step="1">Configurações Gerais</h4>
            <el-tooltip content="Clique para acessar o documento contendo maiores informações da Configuração Geral"
              effect="light" :open-delay="100" placement="top">
              <a class="mb-1" href="https://nordwareservices.atlassian.net/wiki/spaces/NCDA/pages/68547740214/Geral"
                target="_blank">
                <i class="material-icons ml-1 pb-2 text-white">link </i>
              </a>
            </el-tooltip>
          </div>
          <div class="m-0 p-0 col-md-6 d-flex justify-content-end">
            <div class="col-sm-8 col-md-auto" v-if="getUserPermission('00_c') && isSAPHANA">
              <button class="custom-flexible-btn" @click="createUserFieldsAndTablesErp()">
                Criar Campos e Tabelas de Usuário
              </button>
            </div>
            <div class="col-sm-4 col-md-auto" v-if="isSAPHANA">
              <button class="custom-flexible-btn" @click="refreshConfiguration()">
                Sincronizar com SAP
              </button>
            </div>
          </div>
        </div>
        <div>
          <el-tabs v-model="activeName">
            <el-tab-pane v-for="(group, index) in configurationGroup" :key="index" :label="group.GroupDescription"
              :name="group.GroupDescription">
              <ConfigurationTab :ref="group.GroupDescription" :group="group.GroupType" :isSAPHANA="isSAPHANA" />
            </el-tab-pane>
          </el-tabs>
        </div>
      </card>
    </div>
    <div class="col-lg-12 col-sm-12">
      <UpdateLog :dataLog="dataLog" />
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <div></div>
        <div>
          <button class="custom-btn-save" @click="save()" v-if="getUserPermission('13_c')">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loadingButton"></span>
            Salvar</button>
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import planMixin from "src/mixins/planMixin.js";
import { mapState, mapActions } from "vuex";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseProgress from "@/components/BaseProgress.vue";
import SettingsLoadingModal from "./components/SettingsLoadingModal";
import ConfigurationTab from "./components/ConfigurationTab";
import { EventBus } from "@/eventBus";
import UpdateLog from "../../components/UpdateLog.vue";
import constants from '@/util/constants';

export default {
  mixins: [planMixin],
  components: {
    BaseInput,
    BaseProgress,
    SettingsLoadingModal,
    ConfigurationTab,
    UpdateLog
  },
  data() {
    return {
      activeName: "Pedido",
      dataLog: [],
      modalShow: true,
      configurationTable: [],
      isSAPHANA: false,
      checkFunctionality: true,
      configurationArray: [],
      loadingButton: false,
    };
  },
  computed: {
    ...mapState(['ecommerce']),
    ...mapState('configurations', ['configurationGroup'])
  },
  methods: {
    ...mapActions('configurations', ['getConfigurations', 'loadConfigurationsSAP', 'updateConfigurations', 'updateAddon']),
    async init() {
      let searching = this.$refs.searching;
      try {
        searching.bahnLoading();
        await this.GetErpPlatformType();
        await this.getConfigurations();
        searching.bahnCheck();

      } catch (error) {
        searching.bahnError(error);
        return;
      }

      try {
        searching.sapLoading();
        if (this.isSAPHANA) {
          await this.getConfigurationsSAP();
        }
        this.modalShow = false;
      } catch (error) {
        searching.sapError(error);
        return;
      }

      await this.getLogs();

      EventBus.$emit("fillConfigurations");
      searching.completed();
    },
    async getLogs() {
      await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        this.$store.state.ecommerce.Name +
        "&entity=Configuration"
      )
        .then(response => {
          this.dataLog = response.data;
        });
    },
    async save() {
      try {
        this.loadingButton = true;
        let payload = this.fillPayloadToSave();

        if(payload.length === 0) {
          this.$showSuccess("Nenhuma configuração foi alterada!");
          return;
        }
        await this.saveConfiguration(payload);
        this.$showSuccess("Configurações salva com sucesso!");
      } catch (error) {
        let err = error;
        if (error.response != null && error.response.data != null)
          err = error.response.data;

        this.$showError(
          `Ops! Houve um erro ao salvar as configurações. Motivo: ${err}`
        );
      } finally {
        this.loadingButton = false;
        await this.getConfigurations();
        await this.getLogs();
      }
    },
    fillPayloadToSave() {
      let payload = [];

      this.configurationGroup.forEach(group => {
        let ref = this.$refs[group.GroupDescription];
        if (ref) {
          let configurations = ref[0].getValue();
          payload = [...payload, ...configurations];
        }
      });

      return payload;
    },
    async saveConfiguration(configurations) {
      await this.updateConfigurations(configurations);
    },
    async GetErpPlatformType() {
      this.isSAPHANA = this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform);
    },
    GetErpPlatformTypeByKey(key) {
      return ("Sap Hana").includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
    async getConfigurationsSAP() {
      let attempts = 3;
      for (let index = 0; index < attempts; index++) {
        EventBus.$emit("attempt", `Tentativa ${index + 1} de 3`);
        try {
          await this.loadConfigurationsSAP();
          index = 3;
        } catch (error) {
          if (index === 2) {
            throw error;
          }
        }
      }
    },
    async refreshConfiguration() {
      try {
        await this.$bahngleis
          .post("Configuration/RefreshERPConfiguration/" + this.ecommerce.Name);
        await this.init();
        this.$showSuccess("Sincronização concluída com sucesso!");
      } catch (error) {
        this.$showError(error);
      }
    },
    async createUserFieldsAndTablesErp() {
      var errorMessage = "Ocorreu um erro ao tentar criar os campos e tabelas. Verifique o log para mais detalhes!";
      try {
        var response = await this.$bahngleis.post("Configuration/CreateUserFieldsAndTablesErp");
        await this.init();
        if (response.data.success)
          this.$showSuccess("Criação de campos e tabelas concluído com sucesso!");
        else
          this.$showError(errorMessage);
      } catch (error) {
        this.$showError(errorMessage);
      }
    },
    closeModal() {
      this.modalShow = false;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
  async mounted() {
    await this.init();
    EventBus.$on('changeTab', (tab) => this.activeName = tab);
    EventBus.$on("changeEcommerce",  () => this.$nextTick(setTimeout(() => window.location.reload(), 1000)));
  }
};
</script>

<style>
.card-grande {
  overflow: auto;
  height: 77vh;
}

.input-card-grande {
  max-width: 1560px;
}

.input-select {
  max-width: 1555px;
}

.configuration-group {
  border-bottom: 1px solid #00adb7;
  padding: 7px 0px;
}

.card-tasks {
  height: auto;
}

div.card-grande .icon-custom {
  color: white;
  font-size: 1em;
  cursor: pointer;
}

.el-tabs__item {
  color: #eff6f7 !important;
}

.el-tabs__item.is-active {
  color: #00adb7 !important;
}

.el-tabs__item:hover {
  color: #00adb7 !important;
}

.el-tabs__active-bar {
  background-color: #00adb7 !important;
}
</style>
