<template>
    <Modal :modalContentClasses="'card'" :showClose="false" :show="show">
        <h4 slot="header" class="card-title">Adicionar Destinatário</h4>
        <div class="col-12 col-sm-12">
            <base-input>
                <label>Nome:</label>
                <el-input placeholder="Informe o Nome" rows="5" v-model="notificationEmailRecipients.Name" />
            </base-input>
            <base-input>
                <label>Email:</label>
                <el-input placeholder="Informe o E-mail" rows="5" v-model="notificationEmailRecipients.Email" />
            </base-input>
        </div>
        <div class="modal-footer d-flex justify-content-end">
            <el-button type="primary" @click="addEmailRecipients" class="ml-auto">Adicionar</el-button>
            <el-button @click="closeModal" style="margin-left: 10px;">Cancelar</el-button>
        </div>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal'

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Modal
    },
    data() {
        return {
            notificationEmailRecipients: {
                Name: null,
                Email: null
            },
            msg: null
        }
    },
    methods: {
        addEmailRecipients() {
            if (!this.validate())
                return;

            this.$emit("evtAddedCondition", this.notificationEmailRecipients);
            this.notificationEmailRecipients = {
                Name: null,
                Email: null
            }
        },
        closeModal() {
            this.notificationEmailRecipients = {
                Name: null,
                Email: null
            }
            this.$emit("evtClose");
        },
        validate() {
            if (!this.notificationEmailRecipients.Name) {
                this.$showError("Nome obrigatório.");
                return false;
            } else if (!this.notificationEmailRecipients.Email) {
                this.$showError("E-mail obrigatório.");
                return false;
            } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.notificationEmailRecipients.Email)) {
                this.$showError("Insira um endereço de email válido.");
                return false;
            } else
                return true;
        }
    },
    mounted() {
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    }
}
</script>
<style>
.modal-footer button {
    margin: 10px;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
    color: #FFF;
    background-color: #7ed3d8;
    border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
    color: #FFF;
    border-color: #00ADB7;
    background-color: #00ADB7;
}

.el-button--primary {
    color: #FFF;
    background-color: #4eacb1;
    border-color: #4eacb1;
}
</style>
