<template>
    <div class="row"> 
        <div class="col-12">
            <h4 slot="header" class="card-title">
                <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
                    <i class="material-icons">arrow_back</i>
                </base-button> Cadastro Utilização
            </h4>
        </div> 
        <div class="col-12">
            <UsageForm ref="form"/>
        </div>   
        <div class="col-12">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
                <div></div>
                <div>
                    <button class="custom-btn-save" @click="save">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import UsageForm from "../components/UsageForm.vue";

export default {
    components : {
        UsageForm
    },
    methods: {
        ...mapActions('usage',['createUsage',]),
        async save(){
            try {
                let form = this.$refs.form;
                if(!form.validate())
                    return;

                await this.createUsage(form.getValue());
                this.$showSuccess("O cadastro foi inserido com sucesso");
                this.$router.push({ name : 'Usage'});
            } catch (error) {
                this.$showError("Ocorreu um erro ao inserir o cadastro");
            }
        }
    },
}
</script>