export default {
    data() {
        return {
            attributeTypes: [
                { label: "Produto", value: "Product" },
                { label: "SKU", value: "Sku" },
            ],
            attributeValues: [
                { label: "Texto", value: "Text" },
                { label: "Inteiro", value: "Integer" },
                { label: "Decimal", value: "Decimal" },
            ],
        }
    }
}