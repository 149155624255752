<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h3 slot="header" class="card-title">Cadastro de Produto</h3>
      <ProductForm :typeForm="typeForm" ref="form" />
      <div slot="footer" class="col-12 d-flex flex-row-reverse">
          <button class="custom-btn-add ml-2" @click="$router.push({ name: 'ProductList' })">Voltar</button>
          <el-dropdown split-button @command="handleCommand" @click="handleClick">
            {{ dropdownName }}
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-if="dropdownName === 'Salvar e Enviar'"
                  command="Salvar e Enviar">Salvar</el-dropdown-item>
                <el-dropdown-item v-else command="Salvar" @click="selectItem('Salvar e Enviar')">Salvar e
                  Enviar</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
      </div>
    </card>
  </transition>
</template>
<script>
import ProductForm from "@/pages/Product/components/ProductForm";
import { mapActions } from "vuex";
import constants from "@/util/constants";

export default {
  components: {
    ProductForm,
  },
  data() {
    return {
      dropdownName: 'Salvar',
      typeForm: constants.typeForm.create,
    };
  },
  methods: {
    ...mapActions("product", ["saveProduct", "sendProductEcommerce"]),
    handleCommand(command) {
      this.dropdownName = command === 'Salvar' ? 'Salvar e Enviar' : 'Salvar';
    },
    async handleClick() {
      const shouldSend = this.dropdownName === "Salvar e Enviar";
      this.save(shouldSend);
    },
    async send() {
      await this.sendProductEcommerce().then(() => {
        this.$showSuccess("Produto enviado ao ecommerce com sucesso");
        this.$router.push({ name: "ProductList" });
      }).catch(error => {
        if (error.response.data)
          this.$showError(error.response.data);
        else
          this.$showError(error);
      });
    },
    async save(shouldSend) {
      let form = this.$refs.form;

      if (!form.isFormValid())
        return;

      let payload = form.prepareFormToSend();
      await this.saveProduct(payload).then(resp => {
        this.$showSuccess("Produto salvo com sucesso");
        if (shouldSend)
          this.send();

        this.$router.push({ name: "ProductList" });
      }).catch(error => {
        if (error.response.data)
          this.$showError(error.response.data);
        else
          this.$showError(error);
      });
    },
  },
};
</script>
<style>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #00ADB7;
  color: #ffffff !important;
}

.el-button-group>.el-button.is-active,
.el-button-group>.el-button:active,
.el-button-group>.el-button:focus,
.el-button-group>.el-button:hover {
  color: #ffffff !important;
  background-color: #00ADB7 !important;
}

.el-button {
  display: inline-block;
  color: #ffffff;
  background-color: #00ADB7;
}
</style>