import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return {
          v$: useVuelidate(),
        };
    },
    data() {
        return {
            errs : []
        }
    },
    methods: {
        messageError(param) {
            return param.$error ? param.required.$message : null;
        },
        validate(){
            this.v$.$touch();
            this.throwMessageError();
            return !this.v$.$invalid;
        },
        throwMessageError(){
            if(this.v$.$errors.length > 0){
                this.v$.$errors.forEach(element => {
                    if(!this.errs.some(a => a == element.$message)){
                        this.$showError(element.$message); 
                        this.errs.push(element.$message);
                    }
                });
            }

            setTimeout(() => {
                this.errs = [];
              }, 100);
        }
    }
}