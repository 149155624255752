<template>
    <div align="center">
        <h2 class="card-title" style="color:rgb(43,57,115)">
            Bem vindo a bordo
        </h2>
        <h3 class="pl-1 pr-1" style="color:rgb(43,57,115)">
            Primeiramente, muito obrigado por fazer parte da Nordware. O Bahn
            vai te ajudar a fazer as integrações necessárias entre o seu
            e-commerce e o ERP.
        </h3>
        <h3 style="color:rgb(43,57,115)">
            Te dando dados para monitoramento e tomadas de decisão, e
            eliminando gargalos, te dando mais tempo para focar em outros
            processos e ter mais faturamento!
        </h3>
        <div class="row">
            <div class="col-sm-6">
                <base-input name="ERP">
                    <el-select placeholder="ERP" class="select-primary" v-model="newEcommerce.Erp.Platform">
                        <el-option :name="option.label" v-for="option in erpList" :value="option.value"
                            :label="option.label" :key="option.label"></el-option>
                    </el-select>
                </base-input>
            </div>
            <div class="col-sm-6">
                <base-input name="Plataforma">
                    <el-select placeholder="Ecommerce" class="select-primary" v-model="newEcommerce.platform">
                        <el-option :name="option.label" v-for="option in platFormList" :value="option.value"
                            :label="option.label" :key="option.label"></el-option>
                    </el-select>
                </base-input>
            </div>
            <div class="col-sm-12">
                <base-input name="Name" placeholder="Nome do e-commerce" v-model="newEcommerce.name"
                    addon-left-icon="tim-icons icon-caps-small">
                </base-input>
            </div>
            <div class="col-sm-12">
                <base-input name="URL" placeholder="URL" v-model="newEcommerce.url"
                    addon-left-icon="tim-icons icon-link-72">
                </base-input>
            </div>
            <div class="col-sm-6">
                <base-input name="User" placeholder="User" v-model="newEcommerce.user"
                    addon-left-icon="tim-icons icon-single-02">
                </base-input>
            </div>
            <div class="col-sm-6">
                <base-input name="Password" placeholder="Password" v-model="newEcommerce.password"
                    addon-left-icon="tim-icons icon-key-25" type="password">
                </base-input>
            </div>
        </div>
    </div>
</template>
<script>
import constants from '../../../util/constants';
import { EventBus } from "@/eventBus";

export default {
    data() {
        return {
            platFormList: [],
            erpList: [],
            newEcommerce: {
                name: null,
                user: null,
                url: "https://",
                password: null,
                companyId: null,
                platform: null,
                Erp: {
                    Platform: null
                },
                hubId: null,
                active: true,
            },
        }
    },
    methods: {
        getPlatformList() {
            let platforms = constants.EcommercePlatForm.map((el) => {
                return { value: el.Value, label: el.Label };
            });
            this.platFormList = platforms;
        },
        getErpList() {
            let erps = constants.ErpPlatform.map((el) => {
                return { value: el.Value, label: el.Label };
            });
            this.erpList = erps;
        },
        async save() {
            try {
                if (!this.validate()) return;
                await this.$bahngleis.post(`/Ecommerce`, this.newEcommerce, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }).then(async () => {
                    const loading = this.$loading({
                        lock: true,
                        text: 'Criando Configurações..',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    loading.close();
                    this.$showSuccess("Novo E-commerce inserido com sucesso.");
                    this.$router.push({ name: "Dashboard" });
                    setTimeout(window.location.reload(), 2000);
                });
            } catch (error) {
                this.$showError(error);
            }
        },
        validate() {
            if (this.newEcommerce.name == null) {
                this.$showError("Por favor, informe o nome do <b>E-Commerce</b>");
                return false;
            } else if (this.newEcommerce.url == null) {
                this.$showError("Por favor, informe a URL do <b>E-Commerce</b>");
                return false;
            } else if (this.newEcommerce.password == null) {
                this.$showError("Por favor, informe a senha do <b>E-Commerce</b>");
                return false;
            } else if (this.newEcommerce.platform == null) {
                this.$showError("Por favor, informe a plataforma do <b>E-Commerce</b>");
                return false;
            }

            return true;
        },
    },
    mounted() {
        this.getErpList();
        this.getPlatformList();
        EventBus.$on("saveEcommerce", () => this.save());
    },
}
</script>
<style></style>
