<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h4>Cadastro de Atributo</h4>
      <div class="row">
        <div class="col-3">
          <base-input label="Nome" v-model="form.name" :error="messageError(v$.form.name)" />
        </div>
        <div class="col-5">
          <base-input label="Descrição" :error="messageError(v$.form.description)" v-model="form.description" />
        </div>
        <div class="col-1">
          <h5 style="margin-left: 10px">Ativo?</h5>
          <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
            v-model="form.active" :sync="true" />
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <base-input label="Tipo" :error="messageError(v$.form.specificationType)">
            <el-select class="select-primary" placeholder="Tipo" v-model="form.specificationType">
              <el-option class="select-primary" v-for="attributeType in attributeTypes" :key="attributeType.value"
                :label="attributeType.label" :value="attributeType.value"></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-2">
          <base-input label="Tipo do Valor" :error="messageError(v$.form.valueType)">
            <el-select class="select-primary" placeholder="Tipo do Valor" v-model="form.valueType">
              <el-option class="select-primary" v-for="valueType in attributeValues" :key="valueType.value"
                :label="valueType.label" :value="valueType.value"></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-2">
          <base-input label="Grupo">
            <el-select v-model="form.attributeGroupId" filterable placeholder="Digite o nome do Grupo"
              :filter-method="getGroup">
              <el-option v-for="item in groups" :key="item.Id" :label="item.Name" :value="item.Id">
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <button @click="$router.go(-1)" class="custom-btn-add m-1">Voltar</button>
        <button class="custom-btn-save m-1" @click="save">Salvar</button>
      </div>
    </card>
  </transition>
</template>
<script>
import validateMixin from "@/mixins/validateMixin";
import attributesMixin from "../mixins/attributesMixin";
import { helpers, required } from "@vuelidate/validators";
import { mapActions, mapState } from "vuex";

export default {
  mixins: [validateMixin, attributesMixin],
  data() {
    return {
      loading: false,
      groups: [],
      form: {
        name: "",
        description: "",
        active: true,
        specificationType: null,
        valueType: null,
        attributeGroupId: null
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required: helpers.withMessage("Nome obrigatório", required) },
        description: {
          required: helpers.withMessage("Descrição obrigatória", required),
        },
        specificationType: {
          required: helpers.withMessage("Tipo obrigatório", required),
        },
        valueType: {
          required: helpers.withMessage("Valor obrigatório", required),
        }
      },
    };
  },
  computed: {
    ...mapState(["ecommerce"])
  },
  methods: {
    ...mapActions("product", ["saveAttribute", "getGroups"]),
    save() {
      if (!this.validate()) return;

      this.saveAttribute(this.form)
        .then(() => {
          this.$showSuccess("Atributo salvo com sucesso");
          this.$router.push({ name: "Attributes" });
        })
        .catch((error) => this.$showError(error.response.data));
    },
    getGroup(query) {
      if (query === '')
        return;

      this.getGroups({ Name: query, EcommerceName: this.ecommerce.Name })
        .then((res) => {
          this.groups = res.data.Records;
        });
    }
  },
  mounted() {
    this.getGroups({ EcommerceName: this.ecommerce.Name })
      .then((res) => { this.groups = res.data.Records; })
      .catch(error => this.$showError(error.response.data));
  },
};
</script>
<style scoped>
.form-group.has-danger .error {
  color: red !important;
}
</style>