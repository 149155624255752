<template>
  <Modal :modalContentClasses="'card'" :showClose="false" :show="show">
    <h4 slot="header" class="card-title">Adicionar Condição</h4>
    <div class="col-12 col-sm-12">
      <base-input>
        <label>Condição:</label>
        <el-select v-model="observationDocumentConditions.DocumentCondition" placeholder="Selecione"
          @change="changeCondition">
          <div v-if="[1, 2, 4, 5].includes(observationDocument.DocumentType)">
            <el-option v-for="item in conditionOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </div>
          <div v-else-if="[8].includes(observationDocument.DocumentType)">
            <el-option v-for="item in conditionOptionsOrders" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </div>
          <div v-else>
            <el-option v-for="item in conditionOptionsIncomingPayments" :key="item.value" :label="item.label"
              :value="item.value">
            </el-option>
          </div>
        </el-select>
      </base-input>
      <base-input>
        <label>Tipo da Condição:</label>
        <el-select v-model="observationDocumentConditions.ConditionType" placeholder="Selecione"
          :disabled="observationDocumentConditions.DocumentCondition != 1 ? false : true">
          <el-option v-for="item in conditionTypeOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </base-input>
      <base-input>
        <label>Valor da condição:</label>
        <el-input placeholder="Informe o Valor da Condição" v-model="observationDocumentConditions.ConditionValue"
          :disabled="observationDocumentConditions.DocumentCondition != 1 ? false : true">
        </el-input>
      </base-input>
    </div>
    <div class="modal-footer d-flex justify-content-end">
      <el-button type="primary" @click="addConditionValue" class="ml-auto">Adicionar</el-button>
      <el-button @click="closeModal" style="margin-left: 10px;">Cancelar</el-button>
    </div>
  </Modal>
</template>
<script>
import Modal from '@/components/Modal'
import constants from '../../../util/constants'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    observationDocument: {
      type: Object,
      default: () => {
        return {
          DocumentType: 0,
        }
      }
    }
  },
  components: {
    Modal
  },
  data() {
    return {
      observationDocumentConditions: {
        DocumentCondition: null,
        ConditionType: 0,
        ConditionValue: null
      },
      msg: null,
      conditionOptions: constants.ConditionOptions,
      conditionOptionsOrders: constants.ConditionOptionsOrders,
      conditionOptionsIncomingPayments: constants.ConditionOptionsIncomingPayments,
      conditionTypeOptions: constants.ConditionTypeOptions,
    }
  },
  methods: {
    addConditionValue() {
      if (!this.validate())
        return;

      this.$emit("evtAddedCondition", this.observationDocumentConditions);
      this.observationDocumentConditions = {
        DocumentCondition: null,
        ConditionType: 0,
        ConditionValue: null
      }
    },
    closeModal() {
      this.observationDocumentConditions = {
        DocumentCondition: null,
        ConditionType: 0,
        ConditionValue: null
      }
      this.$emit("evtClose");
    },
    validate() {
      if (!this.observationDocumentConditions.DocumentCondition) {
        this.$showError("Condição obrigatório.");
        return false;
      } else if (!this.observationDocumentConditions.ConditionType && this.observationDocumentConditions.DocumentCondition != 1) {
        this.$showError("Tipo da condição obrigatório.");
        return false;
      } else if (!this.observationDocumentConditions.ConditionValue && this.observationDocumentConditions.DocumentCondition != 1) {
        this.$showError("Valor da condição obrigatório.");
        return false;
      }
      return true;
    },
    changeCondition(type) {
      switch (type) {
        case 1:
          this.observationDocument.ObservationDocumentConditions.ConditionType = null;
          this.observationDocument.ObservationDocumentConditions.ConditionValue = null;
          break;
      }

    },
  },
  mounted() {
    this.dialogVisible = true;
  },
  destroyed() {
    this.dialogVisible = false;
  }
}
</script>
<style>
.modal-footer button {
  margin: 10px;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
  color: #FFF;
  background-color: #7ed3d8;
  border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
  color: #FFF;
  border-color: #00ADB7;
  background-color: #00ADB7;
}

.el-button--primary {
  color: #FFF;
  background-color: #4eacb1;
  border-color: #4eacb1;
}
</style>
