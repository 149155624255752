<template>
  <modal :show="show">
    <div class="mt-3">
      <div class="row">
        <div class="col-3" align="right">
          <img
            v-if="isBahnLoading"
            width="60"
            height="60"
            src="@/assets/images/334-loader-5.gif"
          />
          <svg
            v-if="isBahnCheck"
            width="50"
            height="50"
            version="1.1"
            id="Warstwa_3"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 430 430"
            style="enable-background: new 0 0 430 430"
            xml:space="preserve"
          >
            <g>
              <path
                class="st0"
                d="M154.76,330.29c-1.64,0-3.27-0.62-4.52-1.87l-97.47-97.33c-2.5-2.5-2.51-6.55-0.01-9.06
                c2.5-2.5,6.55-2.51,9.06-0.01l92.94,92.81l213.42-213.25c2.5-2.5,6.56-2.5,9.06,0c2.5,2.5,2.5,6.56,0,9.06L159.29,328.42
                C158.04,329.67,156.4,330.29,154.76,330.29z"
              />
            </g>
          </svg>
          <img
            v-if="isBahnError"
            width="60"
            height="60"
            src="@/assets/images/1140-error-outline.gif"
          />
        </div>
        <h4 class="col-9 pt-4" :style="getBahnStyle">
          {{ getDescriptionBahn }}
        </h4>
      </div>
      <div v-if="anyError">
        <p><strong>Motivo:</strong></p>
        <p>{{ error }}</p>
      </div>
    </div>
    <template v-slot:footer>
      <button
        v-if="anyError"
        type="button"
        @click="$router.push({ name: 'Dashboard' })"
        class="custom-btn-add mx-auto"
        aria-label="Close"
      >
        OK
      </button>
    </template>
  </modal>
</template>
<script>
import Modal from "../../../components/Modal.vue";

export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchBahn: "awaiting",
      error: "",
      anyError: false,
    };
  },
  computed: {
    isBahnLoading() {
      return this.searchBahn == "loading";
    },
    isBahnAwaiting() {
      return this.searchBahn == "awaiting";
    },
    isBahnCheck() {
      return this.searchBahn == "check";
    },
    isBahnError() {
      return this.searchBahn == "error";
    },
    getBahnStyle() {
      switch (this.searchBahn) {
        case "loading":
          return "color: #131432";
        case "error":
          return "color: #131432";
        case "check":
          return "color: #08a88a";
        default:
          return "color: gray";
      }
    },
    getDescriptionBahn() {
      switch (this.searchBahn) {
        case "error":
          return "Ops! Houve um erro ao criar as configurações no Bahn.";
        default:
          return "Criando configurações no Bahn.";
      }
    },
  },
  methods: {
    bahnLoading() {
      this.searchBahn = "loading";
    },
    bahnError(error) {
      this.searchBahn = "error";
      this.anyError = true;
      if(error.response != null && error.response.data != null)
        this.error = error.response.data;
      else
        this.error = error;
    },
    bahnCheck() {
      this.searchBahn = "check";
    },
  },
};
</script>
<style scoped>
.st0 {
  fill: #08a88a;
}
.st1 {
  fill: #08a88a;
}
.st2 {
  fill: none;
  stroke: #08a88a;
  stroke-width: 12.25;
  stroke-miterlimit: 14;
}
.st3 {
  fill-opacity: 0;
}
.st4 {
  fill: #131432;
}
.st5 {
  fill: none;
}
</style>