<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h4>Cadastro de Atributo</h4>
      <div class="row">
        <div class="col-3">
          <base-input
            label="Nome"
            v-model="form.Name"
            :error="messageError(v$.form.Name)"
          />
        </div>
        <div class="col-5">
          <base-input
            label="Descrição"
            :error="messageError(v$.form.Description)"
            v-model="form.Description"
          />
        </div>
        <div class="col-1">
          <h5 style="margin-left: 10px">Ativo?</h5>
          <toggle-button
            :labels="{ checked: 'ON', unchecked: 'OFF' }"
            color="#7dd7dc"
            input
            type="checkbox"
            v-model="form.Active"
            :sync="true"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <base-input
            label="Tipo"
            :error="messageError(v$.form.SpecificationType)"
          >
            <el-select
              class="select-primary mb-3 pagination-select"
              placeholder="Tipo do Atributo"
              v-model="form.SpecificationType"
            >
              <el-option
                class="select-primary"
                v-for="attributeType in attributeTypes"
                :key="attributeType.value"
                :label="attributeType.label"
                :value="attributeType.value"
              ></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-2">
          <base-input
            label="Tipo do Valor"
            :error="messageError(v$.form.ValueType)"
          >
            <el-select
              class="select-primary mb-3 pagination-select"
              placeholder="Tipo do Valor"
              v-model="form.ValueType"
            >
              <el-option
                class="select-primary"
                v-for="valueType in attributeValues"
                :key="valueType.value"
                :label="valueType.label"
                :value="valueType.value"
              ></el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-2">
          <base-input label="Grupo">
            <el-select v-model="form.AttributeGroupId" filterable placeholder="Digite o nome do Grupo"  :filter-method="getGroup">
              <el-option v-for="item in groups" :key="item.Id" :label="item.Name" :value="item.Id">
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="col-4">
        <button @click="$router.go(-1)" class="custom-btn-add m-1">Voltar</button>
        <button class="custom-btn-save m-1" @click="save">Salvar</button>
      </div>
    </card>
  </transition>
</template>
<script>
import validateMixin from "@/mixins/validateMixin";
import attributesMixin from "../mixins/attributesMixin";
import { helpers, required } from "@vuelidate/validators";
import { mapActions, mapState } from "vuex";

export default {
  mixins: [validateMixin, attributesMixin],
  data() {
    return {
      groups: [],
      form: {
        Id : null,
        Name: "",
        Description: "",
        Active: true,
        SpecificationType: null,
        ValueType: null,
      },
    };
  },
  validations() {
    return {
      form: {
        Name: { required: helpers.withMessage("Nome obrigatório", required) },
        Description: {
          required: helpers.withMessage("Descrição obrigatória", required),
        },
        SpecificationType: {
          required: helpers.withMessage("Tipo obrigatório", required),
        },
        ValueType: {
          required: helpers.withMessage("Valor obrigatório", required),
        },
      },
    };
  },
  computed : {
    ...mapState("product",["attributeCurrent"]),
    ...mapState(["ecommerce"])
  },
  methods: {
    ...mapActions("product", ["updateAttribute","getAttributeById","getGroups"]),
    save() {
      if (!this.validate()) return;

      this.updateAttribute(this.form).then(() => {
        this.$showSuccess("Atributo atualizado com sucesso");
        this.$router.push({ name: "Attributes" });
      });
    },
    getGroup(query) {
      if (query === '')
        return;

      this.getGroups({ Name: query, EcommerceName: this.ecommerce.name})
        .then((res) => {
          this.groups = res.data;
        });
    }
  },
  mounted() {
    let id = this.$route.params.id;
    this.getAttributeById(id).then(() => {
      this.form = this.attributeCurrent;
    }).catch(error => this.$showError(error.response.data));
    
    this.getGroups({ EcommerceName: this.ecommerce.name})
    .then((res) => { this.groups = res.data.Records;})
    .catch(error => this.$showError(error.response.data));
  },
};
</script>
<style scoped>
.form-group.has-danger .error {
    color:red !important;
}
</style>