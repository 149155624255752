<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showDetails">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Canais de Venda</h4>
        <div>
          <el-row :gutter="10" style="padding: 0 15px;">
            <el-col :lg="2" :sm="12">
              <base-input>
                <el-select class="select-primary" v-model="Filters.freeShipping">
                  <el-option v-for="item in Filters.freeShippingOptions" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </base-input>
            </el-col>
            <el-col :lg="3" :sm="12">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome"
                  v-model="Filters.name"></el-input>
              </base-input>
            </el-col>
            <el-col :lg="3" :sm="12">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Canal SAP"
                  v-model="Filters.sapChannel"></el-input>
              </base-input>
            </el-col>
            <el-col :lg="2" :sm="12">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Store SAP"
                  v-model="Filters.sapStore"></el-input>
              </base-input>
            </el-col>
            <el-col :lg="3" :sm="12">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Tipo de Cliente"
                  v-model="Filters.customerType"></el-input>
              </base-input>
            </el-col>
            <el-col :lg="3" :sm="12">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="CNPJ"
                  v-model="Filters.cnpj"></el-input>
              </base-input>
            </el-col>
            <el-col :lg="2" :sm="12">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Indicador"
                  v-model="Filters.indicatorOfPresence"></el-input>
              </base-input>
            </el-col>
            <el-col :lg="2" :sm="12">
              <button class="custom-btn-filter" @click="Filter()">
                Filtrar
              </button>
            </el-col>
            <el-col :lg="2" :sm="12">
              <button class="custom-btn-filter" @click="ClearFilter(); GetChannels();">
                Limpar filtro
              </button>
            </el-col>
            <el-col :lg="2" :sm="12">
              <button class="custom-btn-add" @click="AddNew()"
                v-if="getUserPermission('35_c')">Novo</button>
            </el-col>
          </el-row>
        </div>
        <el-table :data="SaleChannels">
          <el-table-column v-for="(column, index) in channels" :key="index" :min-width="column.minWidth"
            :prop="column.prop" :label="column.label" :formatter="column.formatter" align="center"></el-table-column>
          <el-table-column label="Realiza transferência?" align="center" min-width="120em">
            <div slot-scope="props">
              <i v-if="props.row.MakeTransfer" class="far fa-check-circle"></i>
              <i v-else class="far fa-times-circle"></i>
            </div>
          </el-table-column>
          <el-table-column label="Realiza transferência apenas para CPF?" align="center" min-width="120em">
            <div slot-scope="props">
              <i v-if="props.row.MakeTransferOnlyCPF" class="far fa-check-circle"></i>
              <i v-else class="far fa-times-circle"></i>
            </div>
          </el-table-column>
          <el-table-column :min-width="100" align="center" label="Frete">
            <div slot-scope="props">
              <p v-if="props.row.FreeShipping" class="status channel-free-shipping-true">
                Grátis
              </p>
              <p v-else class="status channel-free-shipping-false">
                Pago
              </p>
            </div>
          </el-table-column>
          <el-table-column :min-width="135" align="right" label="Ações">
            <div slot-scope="props">
              <base-button class="like !important btn-link" type="primary" size="sm" icon
                @click="ShowDetails(props.row)" v-if="getUserPermission('35_c')">
                <i class="material-icons">mode_edit_outline</i>
              </base-button>
              <base-button class="edit btn-link" type="danger" size="sm" icon @click="Delete(props.row)"
                v-if="getUserPermission('35_c')">
                <i class="material-icons">delete_outline</i>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
        <div slot="footer" class="
            col-12
            row d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div class="col-lg-4 col-sm-12" style="display: flex; align-items: center; justify-content: center">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-lg-4 col-sm-12" style="display: flex; align-items: center; justify-content: center">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total" @input="Filter"></base-pagination>
          </div>
          <div class="col-lg-4 col-sm-12" style="display: flex; align-items: center; justify-content: center">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="(item, index) in pagination.perPageOptions" :key="index"
                :label="item" :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideDetails()">
            <i class="material-icons">arrow_back</i> </base-button>Cadastro de Canal de Venda
        </h4>
      </div>
      <div class="col-sm-4">
        <card type="tasks" class="stacked-form">
          <base-input class="col-12 table-full-width table-responsive" label="Nome" placeholder="Informar o nome"
            maxlength="150" :required="true" v-model="SaleChannel.EcommerceChannel" />
          <div class="col-12 table-full-width table-responsive">
            <base-input label="Frete">
              <el-select class="select-primary" v-model="SaleChannel.FreeShipping">
                <el-option v-for="item in FreeShippingOptions" :key="item.value" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </base-input>
          </div>
          <base-input class="col-12 table-full-width table-responsive" label="Canal do SAP"
            placeholder="Informar o canal do SAP" maxlength="150" :required="true" v-model="SaleChannel.SAPChannel" />
          <base-input class="col-12 table-full-width table-responsive" label="Store do SAP"
            placeholder="Informar o store do SAP" maxlength="150" :required="true" v-model="SaleChannel.SAPStore" />
          <base-input class="col-12 table-full-width table-responsive" label="Tipo de Cliente"
            placeholder="Informar o tipo de cliente" maxlength="150" :required="true"
            v-model="SaleChannel.CustomerType" />
          <div class="col-12 table-full-width table-responsive">
            <base-input label="CNPJ">
              <el-input placeholder="Informar o CNPJ" v-model="SaleChannel.CNPJ" v-mask="'##.###.###/####-##'"
                maxlength="150"></el-input>
            </base-input>
          </div>
          <base-input class="col-12 table-full-width table-responsive" label="Indicador de presença"
            placeholder="Informar o indicador de presença" maxlength="150" :required="true"
            v-model="SaleChannel.IndicatorOfPresence" />
          <base-input class="col-12 table-full-width table-responsive">
            <label>Realiza transferência?</label><br />
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              class="mt-3" v-model="SaleChannel.MakeTransfer" :sync="true" />
          </base-input>
          <base-input class="col-12 table-full-width table-responsive">
            <label>Realiza transferência apenas para CPF?</label><br />
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
              class="mt-3" v-model="SaleChannel.MakeTransferOnlyCPF" :sync="true" />
          </base-input>
          <div class="
            d-flex
            justify-content-end">
            <div class="m-0 mr-3 p-0">
              <button class="custom-btn-save" @click="Save()">Salvar</button>
            </div>
          </div>
        </card>
      </div>
      <div v-if="SaleChannel.Id > 0" class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>
    </div>
  </transition>
</template>

<script>
import swal from "sweetalert2";
import BasePagination from "@/components/BasePagination.vue";
import { mapState } from "vuex";
import UpdateLog from "../../components/UpdateLog.vue";

export default {
  components: {
    BasePagination,
    UpdateLog
  },
  data() {
    return {
      dataLog: [],
      showDetails: false,
      SaleChannels: [],
      SaleChannel: null,
      toInsert: false,
      Filters: {
        name: null,
        sapChannel: null,
        sapStore: null,
        customerType: null,
        freeShipping: null,
        cnpj: null,
        indicatorOfPresence: null,
        freeShippingOptions: [
          { name: "Todos", value: null },
          { name: "Frete grátis", value: true },
          { name: "Frete pago", value: false }
        ]
      },
      FreeShippingOptions: [
        { name: "Frete grátis", value: true },
        { name: "Frete pago", value: false }
      ],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      showSpinner: false,
      regexCNPJ: /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/
    };
  },
  computed: {
    ...mapState("configurations", ["channels"]),
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    }
  },
  methods: {
    ClearFilter: function () {
      (this.Filters.name = null),
        (this.Filters.sapChannel = null),
        (this.Filters.sapStore = null),
        (this.Filters.customerType = null),
        (this.Filters.freeShipping = null),
        (this.Filters.cnpj = null),
        (this.Filters.indicatorOfPresence = null);
    },
    AddNew: async function () {
      this.SaleChannel = {
        Id: 0,
        EcommerceName: null,
        EcommerceChannel: null,
        SAPChannel: null,
        SAPStore: null,
        CustomerType: null,
        FreeShipping: false,
        CNPJ: null,
        IndicatorOfPresence: null
      };
      this.dataLog = [];
      this.showDetails = true;
      this.toInsert = true;
    },
    ShowDetails: async function (channel) {
      this.SaleChannel = channel;
      this.showDetails = true;
      this.toInsert = false;

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        channel.Id +
        "&entity=Channel"
      );

      this.dataLog = await responseLog.data;
    },
    HideDetails: function () {
      this.SaleChannel = null;
      this.showDetails = false;
    },
    GetChannels: async function () {
      this.showSpinner = true;
      this.SaleChannels = [];
      await this.$bahngleis
        .get(
          `Channel/GetAllByEcommerce?EcommerceName=${this.selectedEcomm}` +
          `&CurrentPage=${this.pagination.currentPage}&PerPage=${this.pagination.perPage}`
        )
        .then(result => {
          if (result.status == 200) this.SaleChannels = result.data.Data;
          this.pagination.total = result.data.Total;
        });
      this.showSpinner = false;
    },
    async Filter() {
      this.SaleChannels = [];
      await this.$bahngleis
        .get(
          `Channel/Filter?EcommerceName=${this.selectedEcomm}` +
          (!this.$isNullOrEmpty(this.Filters.name)
            ? `&Name=${this.Filters.name}`
            : "") +
          (!this.$isNullOrEmpty(this.Filters.sapChannel)
            ? `&SapChannel=${this.Filters.sapChannel}`
            : "") +
          (!this.$isNullOrEmpty(this.Filters.sapStore)
            ? `&SapStore=${this.Filters.sapStore}`
            : "") +
          (!this.$isNullOrEmpty(this.Filters.customerType)
            ? `&CustomerType=${this.Filters.customerType}`
            : "") +
          (!this.$isNullOrEmpty(this.Filters.cnpj)
            ? `&Cnpj=${this.Filters.cnpj}`
            : "") +
          (!this.$isNullOrEmpty(this.Filters.indicatorOfPresence)
            ? `&IndicatorOfPresence=${this.Filters.indicatorOfPresence}`
            : "") +
          (this.Filters.freeShipping !== null
            ? `&FreeShipping=${this.Filters.freeShipping}`
            : "") +
          `&CurrentPage=${this.pagination.currentPage}&PerPage=${this.pagination.perPage}`
        )
        .then(result => {
          if (result.status == 200) this.SaleChannels = result.data.Data;
          this.pagination.total = result.data.Total;
        });
    },
    Validate: function () {
      if (this.$isNullOrEmpty(this.SaleChannel.EcommerceChannel)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>nome</b> do canal"
        );
        return false;
      }
      if (this.$isNullOrEmpty(this.SaleChannel.SAPChannel)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>canal do SAP</b>"
        );
        return false;
      }
      if (this.$isNullOrEmpty(this.SaleChannel.SAPStore)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>store do SAP</b>"
        );
        return false;
      }
      if (this.$isNullOrEmpty(this.SaleChannel.CustomerType)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>tipo de cliente</b>"
        );
        return false;
      }
      if (this.$isNullOrEmpty(this.SaleChannel.CNPJ)) {
        this.ShowWarningNotification("Por favor, informe o <b>CNPJ</b>");
        return false;
      }
      if (!this.regexCNPJ.test(this.SaleChannel.CNPJ)) {
        this.ShowWarningNotification("Formato de <b>CNPJ</b> inválido");
        return false;
      }
      if (this.$isNullOrEmpty(this.SaleChannel.IndicatorOfPresence)) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>indicador de presença</b>"
        );
        return false;
      }
      return true;
    },
    Save: async function () {
      if (this.Validate()) {
        this.SaleChannel.EcommerceName = this.selectedEcomm;
        if (this.toInsert) {
          await this.Insert();
        } else {
          await this.Update();
        }
        this.HideDetails();
        this.ClearFilter();
        await this.GetChannels();
      }
    },
    Insert: async function () {
      await this.$bahngleis
        .post("Channel", this.SaleChannel)
        .then(() => {
          swal({
            title: "Sucesso",
            text: "O canal de venda foi inserido com sucesso",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        })
        .catch(() => {
          swal({
            title: "Atenção",
            text: "Ocorreu um erro ao inserir o canal de venda",
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        });
    },
    Update: async function () {
      await this.$bahngleis
        .put("Channel", this.SaleChannel)
        .then(() => {
          swal({
            title: "Sucesso",
            text: "O canal de venda foi atualizado com sucesso",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        })
        .catch(() => {
          swal({
            title: "Atenção",
            text: "Ocorreu um erro ao atualizar o canal de venda",
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false
          });
        });
    },
    Delete: async function (channel) {
      this.SaleChannel = channel;
      swal({
        title: "Gostaria de excluir o canal?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim"
      }).then(async result => {
        if (result.value) {
          await this.$bahngleis
            .delete(`Channel/${this.SaleChannel.Id}`)
            .then(response => {
              if (response.status == 200) {
                swal({
                  title: "Sucesso",
                  text: "O cadastro foi excluido com sucesso",
                  type: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
                this.Filter();
              } else {
                swal({
                  title: "Atenção",
                  text: "Ocorreu um erro ao excluir o cadastro",
                  type: "error",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
              }
            });
        }
      });
    },
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger"
      });
      return;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
  async mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
    this.GetChannels();
  }
};
</script>

<style scoped>
.channel-free-shipping-true {
  color: #6bdf06;
  border: 1px solid #6bdf06;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  background-color: rgba(107, 223, 6, 0.1);
}

.channel-free-shipping-false {
  color: #c3255b;
  border: 1px solid #c3255b;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  background-color: rgba(195, 37, 91, 0.1);
}
</style>
