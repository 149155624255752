<template>
    <div align="center" class="pa-4 mt-3">
        <div class="row">
            <div class="col-sm-6">
                <base-input name="ERP" required v-validate="modelValidations.platform">
                    <el-select placeholder="ERP" class="select-primary" v-model="ecommerceModel.Erp.Platform">
                        <el-option :name="option.label" v-for="option in erpList" :value="option.value"
                            :label="option.label" :key="option.label"></el-option>
                    </el-select>
                </base-input>
            </div>
            <div class="col-sm-6">
                <base-input name="Plataforma" :error="getError('platform')" required v-validate="modelValidations.platform">
                    <el-select placeholder="Ecommerce" class="select-primary" v-model="ecommerceModel.platform">
                        <el-option :name="option.label" v-for="option in platFormList" :value="option.value"
                            :label="option.label" :key="option.label"></el-option>
                    </el-select>
                </base-input>
            </div>
            <div class="col-sm-12">
                <base-input name="Name" required placeholder="Nome do e-commerce" v-model="ecommerceModel.name"
                    v-validate="modelValidations.Name" :error="getError('name')"
                    addon-left-icon="tim-icons icon-caps-small">
                </base-input>
            </div>
            <div class="col-sm-12">
                <base-input name="URL" required placeholder="URL" v-model="ecommerceModel.url"
                    v-validate="modelValidations.url" :error="getError('url')" addon-left-icon="tim-icons icon-link-72">
                </base-input>
            </div>
            <div class="col-sm-6">
                <base-input name="User" required placeholder="User" v-model="ecommerceModel.user"
                    v-validate="modelValidations.User" :error="getError('user')"
                    addon-left-icon="tim-icons icon-single-02">
                </base-input>
            </div>
            <div class="col-sm-6">
                <base-input name="Password" required placeholder="Password" v-model="ecommerceModel.password"
                    v-validate="modelValidations.password" :error="getError('password')"
                    addon-left-icon="tim-icons icon-key-25" type="password">
                </base-input>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import constants from '../../../util/constants';

export default {
    data() {
        return {
            platFormList: [],
            erpList: [],
            ecommerceModel: {
                name: null,
                user: null,
                url: "https://",
                password: null,
                companyId: null,
                platform: null,
                Erp: {
                    Platform: null
                },
                hubId: null,
                active: true,
            },
            modelValidations: {
                platform: {
                    required: true
                },
                name: {
                    required: true
                },
                url: {
                    required: true,
                    url: {
                        require_protocol: true
                    }
                },
                user: {
                    required: true
                },
                password: {
                    required: true
                }
            }
        }
    },
    computed: {
        ...mapState('wizard', ['ecommerces', 'erp']),
        ...mapState(['company'])
    },
    methods: {
        ...mapActions('wizard', ['saveEcommerce']),
        getError(fieldName) {
            return this.errors.first(fieldName);
        },
        async save() {
            let isValidateForm = await this.$validator.validateAll().then(res => {
                return res;
            });

            if (isValidateForm) {
                await this.$bahngleis.post(`/Ecommerce`, this.ecommerceModel)
                .then(() => { this.$emit("evtFinish"); })
                .catch((error) => this.$showError(error));
            }
        },
        getPlatformList() {
            let platforms = constants.EcommercePlatForm.map((el) => {
                return { value: el.Value, label: el.Label };
            });
            this.platFormList = platforms;
        },
        getErpList() {
            let erps = constants.ErpPlatform.map((el) => {
                return { value: el.Value, label: el.Label };
            });
            this.erpList = erps;
        },
    },
    async mounted() {
        this.getErpList();
        this.getPlatformList();
        EventBus.$on("saveEcommerce", () => this.save());
    },
};
</script>
<style></style>
